import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

import { fadeInOutSectionModal, fadeInOutSubsectionModal } from '../../../../animations';

export interface SidenavMenuLink {
  name: string;
  icon?: string;
  isExternal?: boolean;
  isExpanded?: boolean;
  path?: string;
  links?: SidenavMenuLink[];
}

@Component({
  selector: 'iml-sidenav-menu',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
  ],
  templateUrl: './sidenav-menu.component.html',
  styleUrl: './sidenav-menu.component.scss',
  animations: [
    fadeInOutSectionModal,
    fadeInOutSubsectionModal,
  ],
})
export class SidenavMenuComponent implements OnChanges {
  currentLinks: SidenavMenuLink[] = [];
  sublinks: SidenavMenuLink[] = [];
  isSubMenuOpen = false;

  @Input() links: SidenavMenuLink[] = [];
  @Input() mode: 'sublink'|'full' = 'sublink';
  @Output() menuClosed = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['mode']) {
      this.currentLinks = [];
    } else if(changes['mode'].currentValue !== changes['mode'].previousValue) {
      this.sublinks = [];
      this.currentLinks = this.links;
    }

    if (changes['links'] && changes['links'].currentValue !== changes['links'].previousValue) {
      this.sublinks = [];
      this.currentLinks = changes['links'].currentValue;
    }

    console.log('MENU LINKS', this.links, changes['links']?.currentValue);
  }

  openSubMenu(event: MouseEvent, sublinks: SidenavMenuLink[]) {
    // event.preventDefault();
    // event.stopImmediatePropagation();
    // event.stopPropagation();

    this.sublinks = sublinks;
    this.isSubMenuOpen = true;
  }

  backToMainMenu() {
    this.sublinks = [];
  }

  closeMenu() {
    this.menuClosed.emit();
  }
}
