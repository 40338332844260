<mat-sidenav-container>
  <mat-sidenav #sidenav [(opened)]="opened" [mode]="screen.isDesktop.value ? 'side' : 'over'" opened (mouseenter)="onMenuMouseEnter()" (mouseleave)="onMenuMouseLeave()" style="background: none; border: none;">
<!-- <div class="iml-page-container"> -->
    <iml-sidenav-container style="z-index: 165;">
      <iml-creative-filters [from]="defaultFrom" [to]="defaultTo" (filtersUpdated)="reloadData($event)"></iml-creative-filters>
      <!-- <div style="text-align: right;">
        <button mat-flat-button>
          Reset All
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <!- <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">filter_alt</mat-icon>
        <span class="mat-body-strong">Presets</span>
      </div>
      <iml-sidenav-menu [links]="presets" /> ->
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">tune</mat-icon>
        <span class="mat-body-strong">Customize</span>
      </div>
      <div disabled style="margin-top: 5px; margin-bottom: 0px;">
        <mat-form-field appearance="outline">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date">
            <input matEndDate placeholder="End date">
          </mat-date-range-input>
          <!- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> ->
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">switch_access_2</mat-icon>
        <span class="mat-body-strong">Request Type</span>
      </div>
      <div class="filter-content">
        <mat-chip-listbox formControlName="status" multiple aria-label="Status">
          <mat-chip-option>Creative</mat-chip-option>
          <mat-chip-option>RFP</mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">page_info</mat-icon>
        <span class="mat-body-strong">Status</span>
      </div>
      <div class="filter-content">
        <mat-chip-listbox formControlName="type" multiple aria-label="Type">
          <mat-chip-option>New</mat-chip-option>
          <mat-chip-option>In Progress</mat-chip-option>
          <mat-chip-option>Released</mat-chip-option>
        </mat-chip-listbox>
      </div> -->
      <!-- <div class="filter-content">
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
          <span style="flex: 1;">Insertion Order</span>
          <mat-slide-toggle style="flex: 0 0 auto;" />
        </div>
      </div> -->
      <!-- <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">page_info</mat-icon>
        <span class="mat-body-strong">Campaign Status</span>
      </div>
      <div class="filter-content">
        <mat-chip-listbox multiple aria-label="Status">
          <mat-chip-option>Live</mat-chip-option>
          <mat-chip-option>Scheduled</mat-chip-option>
          <mat-chip-option>Ended</mat-chip-option>
        </mat-chip-listbox>
      </div> -->
      <!-- <div class="filter-header">
        <mat-icon fontSet="material-symbols-outlined">switch_access_2</mat-icon>
        <span class="mat-body-strong">Campaign Type</span>
      </div>
      <div class="filter-content">
        <mat-chip-listbox multiple aria-label="Status">
          <mat-chip-option>Display</mat-chip-option>
          <mat-chip-option>Search</mat-chip-option>
          <mat-chip-option>Video</mat-chip-option>
        </mat-chip-listbox>
      </div> -->
    </iml-sidenav-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="iml-page-content-1" (scroll)="checkScroll($event)" [@fadeInOutPage]="getRouteAnimationState()">
      <div class="iml-layout-container">
        <div class="iml-card" style="background: none; border-radius: 0; overflow: initial;">
          <!-- opened && !openInProgress || !opened && openInProgress -->
          <button mat-flat-button [color]="opened ? 'primary' : null" (click)="toggleMenu();" class="iml-filter-btn" style="border-radius: 28px; border: 1px solid black; border-color: var(--iml-primary-color);">
            <!-- <span style="display: inline-flex; flex-direction: row; align-items: center; justify-content: center;"> -->
              Filters
              <mat-icon fontSet="material-symbols-outlined">{{opened ? 'close' : 'tune'}}</mat-icon>
              <!-- <span>Filters</span> -->
            <!-- </span> -->
          </button>
        </div>
        <div class="iml-card">
          <div style="width: 100%; overflow-y: auto;">
            <style>
              .table-in-progress td {
                opacity: 0.5;
              }
            </style>
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="demo-table" [class.table-in-progress]="inProgress && !isLoadingProgress">
              <ng-container matColumnDef="submitted_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disabled sortActionDescription="Sort by email"> Submitted By </th>
                <td mat-cell *matCellDef="let element">
                  @if (element.submittedBy) {
                    <!-- {{element.submittedBy?.email}} -->
                    <div class="user-container"
                      matTooltip="{{element.submittedBy.firstName}} {{element.submittedBy.lastName}} {{element.submittedBy.email}} | {{element.submittedBy.company?.name}}">
                      <iml-user-logo
                        [id]="element.submittedBy.id"
                        [firstName]="element.submittedBy.firstName"
                        [lastName]="element.submittedBy.lastName"
                        [photo]="element.submittedBy.logoUrl"
                      />
                      <div class="user-name">
                        @if (element.submittedBy.lastName?.length > 10) {
                          {{element.submittedBy.firstName}}
                        } @else {
                          {{element.submittedBy.firstName[0]}}. {{element.submittedBy.lastName}}
                        }
                      </div>
                    </div>
                  } @else {
                    <div class="user-container">
                      <!-- <div class="user-warning">
                        <mat-icon inline>warning</mat-icon>
                      </div> -->
                      <div class="user-name">
                        <button mat-stroked-button>Assign</button>
                      </div>
                    </div>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> Project Name </th>
                <td mat-cell *matCellDef="let element">
                  {{element.name}}
                </td>
              </ng-container>
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Request Type </th>
                <td mat-cell *matCellDef="let element">
                  @if (element.type === CreativeRequestType.Creative) {
                    <span class="my-badge full badge-iml-green">Creative</span>
                  } @else {
                    <span class="my-badge full badge-iml-green">RFP</span>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="submitted_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by submission date"> Submitted At </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element.submittedAt | date: 'MMM d, y'}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status </th>
                <td mat-cell *matCellDef="let element">
                  @if (element.status === CreativeRequestStatus.New) {
                    <span class="my-badge full badge-gray">New</span>
                  } @else if (element.status === CreativeRequestStatus.Released) {
                    <span class="my-badge full badge-green">Released</span>
                  } @else {
                    <span class="my-badge full badge-red">No Data</span>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="assets">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disabled sortActionDescription="Sort by start date"> Assets </th>
                <td mat-cell *matCellDef="let element">
                  @for (asset of element.assets; track $index) {
                    <!-- <span class="my-badge badge-item badge-iml-blue">{{asset.type}}</span> -->
                    @if (asset.isReleased) {
                      <a mat-flat-button color="primary" [href]="asset.url" [matTooltip]="asset.name" target="_blank" class="asset-link">
                        {{asset.type}}
                      </a>
                    } @else {
                      <a mat-stroked-button color="primary" [href]="asset.url" [matTooltip]="asset.name" target="_blank" class="asset-link">
                        {{asset.type}}
                      </a>
                    }
                  }
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            @if (isLoadingProgress) {
              <div>
                <mat-progress-spinner style="margin-left: auto; margin-right: auto;"
                  class="example-margin"
                  mode="indeterminate"
                  [diameter]="30">
                </mat-progress-spinner>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
  </mat-sidenav-content>
</mat-sidenav-container>
