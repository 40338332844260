
<div class="main-container">
  <!-- !screen.isMobile.value -->
  @if (!screen.isMobile.value) {
    <div class="rail-container">
      <div class="iml-mdc--navigation-rail">
        <div class="iml-mdc--menu-button">
          <button mat-icon-button (click)="toggleMenu()" class="example-icon" aria-label="Example icon-button with menu icon">
            <mat-icon fontSet="material-symbols-outlined">{{opened || menu.isPageMenuOpened.value ? 'menu_opened' : 'menu'}}</mat-icon>
          </button>
        </div>
        @for (link of links; track link.name) {
          <div class="iml-mdc--rail-item">
            @if (link.isExternal) {
              <!-- <div>
                <a mat-button target="_blank" [href]="link.path" (click)="closeMenu()" class="sidenav-item">
                  <div class="iml-link-content">
                    @if (link.icon) {
                      <mat-icon fontSet="material-symbols-outlined">{{link.icon}}</mat-icon>
                    }
                    <span class="iml-button-text">{{link.name}}</span>
                  </div>
                </a>
              </div> -->
              <a target="_blank" [href]="link.path" class="test" (click)="closeSubMenu()" (mouseenter)="onRailMouseEnter($index)" (mouseleave)="onRailMouseLeave()">
                <div class="mat-primary iml-mdc--icon-cont">
                  <mat-icon fontSet="material-symbols-outlined">{{link.icon}}</mat-icon>
                </div>
                <span class="mat-caption iml-mdc--item-text">{{link.name}}</span>
              </a>
            } @else {
              <a [routerLink]="link.path" routerLinkActive="active" (isActiveChange)="onRouterLinkActive($event, link.path === '/resources/cpm-calculator')" [routerLinkActiveOptions]="{exact: isLinkExact}" class="test" (click)="closeSubMenu()" (mouseenter)="onRailMouseEnter($index)" (mouseleave)="onRailMouseLeave()">
                <div class="mat-primary iml-mdc--icon-cont">
                  <mat-icon fontSet="material-symbols-outlined">{{link.icon}}</mat-icon>
                </div>
                <span class="mat-caption iml-mdc--item-text">{{link.name}}</span>
              </a>
            }
          </div>
        }
      </div>
    </div>
  }
  <mat-sidenav-container>
    <mat-sidenav #sidenav [(opened)]="opened" mode="over" opened (mouseenter)="onMenuMouseEnter()" (mouseleave)="onMenuMouseLeave()" style="background: none; border: none;">
      <iml-sidenav-container>
        @if (screen.isMobile.value) {
          <iml-sidenav-menu mode="full" [links]="links" (menuClosed)="closeSubMenu()" />
        } @else {
          <iml-sidenav-menu [links]="sublinks" (menuClosed)="closeSubMenu()" />
        }
      </iml-sidenav-container>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="test-header" [class.closed]="screen.isMobile.value || !opened && !menu.isPageMenuOpened.value" [style.padding-right.px]="screen.isMobile.value ? 5 : 18">
        <iml-header (menuOpened)="openSubMenu()"></iml-header>
      </div>
      <div class="test-cont">
        <div>
          <router-outlet></router-outlet>
        </div>
        <!-- <div class="test-footer">
          <iml-footer />
        </div> -->
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
