import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'iml-one-sheeters',
  standalone: true,
  imports: [],
  templateUrl: './one-sheeters.component.html',
  styleUrl: './one-sheeters.component.scss'
})
export class OneSheetersComponent {
  caseStudies: { [key: string]: string } = {
    // Products
    'kEdlOJMMY1JyE5': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_collateral_geofencing_v2.pdf',
    'ZGS3K4BTuOsxCX': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_capabilities.pdf',
    'iIWbAI0zjZ5Jq8': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_dooh.pdf',
    'CBtb1nv4VPcMZu': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_newsdriver.pdf',
    '1CB8Kbe4pL7Jf7': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_voter_targeted_campaigns.pdf',
    'lIeB9bEEdlm90D': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_ad_spec_sheet.pdf',
    'yYt83h9dUGEER9': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_audio.pdf',
    'baIzvUiLIC9Ppd': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_crm_targeting.pdf',
    'SNfO6R7CQRKkGq': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_soda.pdf',
    'DFd1mPS7jRdDQB': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_tiktok.pdf',
    '3Zmjyb5V35n11B': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_budget_buying.pdf',
    '3SDBYpyyvzk43m': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_search.pdf',
    'V9lRikmubWoP1M': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_youtube.pdf',
    //Vertical
    'QtuclYKmH7udbO': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_restaurant.pdf',
    'qdDUbanNgLjs59': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_fashion_retail_sales_package.pdf',
    'CkeFYxwWfD8cbl': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_jewelry_package.pdf',
    '2ZVOS2x4hxNImM': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_tax_services.pdf',
    'z6BEHwA8wBXum8': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_auto_service_and_repair.pdf',
    'um7cmUNj8oGHV3': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_cannabis_tips.pdf',
    'kO7LglPUhnPSQ7': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_employee_recruitment.pdf',
    'gHlhI9SfGexJEe': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_events_and_trade_shows.pdf',
    'myKJIaLq8R2zYw': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_gyms_and_fitness.pdf',
    'Tdckvdr9PoeLh5': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_home_improvement.pdf',
    'NNnU7egkkMcOfQ': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_residential_property.pdf',
    'XuMOc9nlyLYwFB': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_tourist_attraction_package.pdf',
    'BXzvJ8PxmSvLtc': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_autobody_package.pdf',
    'mEFNUMirVyNyMR': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_banking_services.pdf',
    '3zkRdcE1bODQGj': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_bars_and_nightclubs.pdf',
    'yDc3MKfGKY4eSu': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_cannabis.pdf',
    '1UZIaWWs9Tm8PG': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_outdoor_enthusiast_package.pdf',
    'tVJ2PRUiMsZ6Un': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_realtor_package.pdf',
    '8u84B8cMck7P6j': 'https://newsdriver-prod-media.s3.us-west-2.amazonaws.com/console/resources/iml_valentines_st_patricks.pdf',
    // 'os-1': 'https://newsdriver-prod-media.s3-us-west-2.amazonaws.com/console/IML-CRM-Capabilities.pdf',
    // 'os-2': 'https://newsdriver-prod-media.s3-us-west-2.amazonaws.com/console/IML-Collateral-Geofencing-v2+copy.pdf',
  };

  selectedDoc = this.sanitizer.bypassSecurityTrustResourceUrl(this.caseStudies['os1-1']);

  @Input()
  set id(id: string) {
    console.log('ID', id);
    let url = this.caseStudies['os-1'];

    if (this.caseStudies[id]) {
      url = this.caseStudies[id];
    }

    this.selectedDoc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  constructor(private sanitizer: DomSanitizer) {}
}
