import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

const colors = [
  '#B71C1C',
  '#880E4F',
  '#4A148C',
  '#311B92',
  '#1A237E',
  '#0D47A1',
  '#01579B',
  '#006064',
  '#004D40',
  '#1B5E20',
  '#F57F17',
  '#FF6F00',
  '#E65100',
  '#BF360C',
];

@Component({
  selector: 'iml-user-logo',
  standalone: true,
  imports: [],
  templateUrl: './user-logo.component.html',
  styleUrl: './user-logo.component.scss'
})
export class UserLogoComponent implements OnChanges {
  color = colors[5];

  @Input() id = '';
  @Input() firstName = '';
  @Input() lastName = '';

  @Input() photo: string|null = null;
  @Input() mode: 'initials'|'photo' = 'initials';
  @Input() size!: number;

  @HostBinding('style.width.px') get width() { return this.size ? this.size : false; }
  @HostBinding('style.height.px') get height() { return this.size ? this.size : false; }
  @HostBinding('style.fontSize.px') get fontSIze() { return this.size ? this.size : false; }
  @HostBinding('style.backgroundColor') get backgroundColor() { return this.color; }
  @HostBinding('class.no-initials') get noInitials() { return this.mode !== 'initials'; }

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    let firstName = this.firstName;
    let lastName = this.lastName;
    let id = this.id;

    let remakeColor = false;

    if (changes['id']) {
      id = changes['id'].currentValue;
      remakeColor = true;
    }

    if (changes['firstName']) {
      firstName = changes['firstName'].currentValue;
      remakeColor = true;
    }

    if (changes['lastName']) {
      lastName = changes['lastName'].currentValue;
      remakeColor = true;
    }

    if (remakeColor) {
      this.color = this.constructColor(id, firstName, lastName);
    }
  }

  constructColor(id: string, firstName: string, lastName: string) {
    let value = id.length > 0 ? id.charCodeAt(0) : 0;

    value += firstName.length > 0 ? firstName.charCodeAt(0) : 0;
    value += lastName.length > 0 ? lastName.charCodeAt(0) : 0;

    return colors[value % colors.length];
  };


}
