import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { SidenavMenuComponent, SidenavMenuLink } from '../../core/components/sidenav/sidenav-menu/sidenav-menu.component';
import { SidenavContainerComponent } from '../../core/components/sidenav/sidenav-container/sidenav-container.component';
import { fadeInOutPage } from '../../animations';

@Component({
  selector: 'iml-resources',
  standalone: true,
  imports: [
    SidenavMenuComponent,
    SidenavContainerComponent,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    MatButtonModule,
    MatListModule,
  ],
  templateUrl: './resources.component.html',
  styleUrl: './resources.component.scss',
  animations: [fadeInOutPage],
})
export class ResourcesComponent {
  links: SidenavMenuLink[] = [
    {
      name: 'Resources',
      isExpanded: true,
      links: [
        { name: 'CPM Calculator', path: '/resources/cpm-calculator' },
        { name: 'SODA Builder', isExternal: true, path: 'https://soda.secureiml.com/' },
      ],
    },
    {
      name: 'Case Studies',
      path: '/resources/case-studies',
      links: [
        { name: 'How effective are Drivers?', path: '/resources/case-studies/KyNhRPcXM6lNZF' },
        { name: 'So You Want Conversions', path: '/resources/case-studies/KzxHhSAGuc1CSy' },
        { name: 'Lawyers, Law Firms, Legal Advocacy', path: '/resources/case-studies/E0t5tr5dBcuNPg' },
        { name: 'To Gate or Not to Gate Your Content?', path: '/resources/case-studies/0fRBgqsdTmb1T5' },
        // { name: 'How effective are Drivers?', path: '/resources/case-studies/case-study-1' },
        // { name: 'Woman\'s Sportswear', path: '/resources/case-studies/case-study-2' },
        // { name: 'Consumer Technology Store', path: '/resources/case-studies/case-study-3' },
        // { name: 'Quick Service Restaraunt', path: '/resources/case-studies/case-study-4' },
      ],
    },
    {
      name: 'Product One Sheeter',
      path: '/resources/one-sheeters',
      links: [
        { name: 'Geo-fencing', path: '/resources/one-sheeters/kEdlOJMMY1JyE5' },
        { name: 'Digital Campaigns | Audience Development', path: '/resources/one-sheeters/ZGS3K4BTuOsxCX' },
        { name: 'Digital Out of Home | DOOH', path: '/resources/one-sheeters/iIWbAI0zjZ5Jq8' },
        { name: 'NewsDriver | Press Release & Pitch Insurance', path: '/resources/one-sheeters/CBtb1nv4VPcMZu' },
        { name: 'Voter Targeted Campaigns', path: '/resources/one-sheeters/1CB8Kbe4pL7Jf7' },
        { name: 'Ad Spec Sheet', path: '/resources/one-sheeters/lIeB9bEEdlm90D' },
        { name: 'Programmatic Audio', path: '/resources/one-sheeters/yYt83h9dUGEER9' },
        { name: 'CRM Targeting | Digital Best Practices', path: '/resources/one-sheeters/baIzvUiLIC9Ppd' },
        { name: 'SODA | Social Open Display Ads', path: '/resources/one-sheeters/SNfO6R7CQRKkGq' },
        { name: 'Understanding TikTok', path: '/resources/one-sheeters/DFd1mPS7jRdDQB' },
        { name: 'Budget vs Impression Media Buying', path: '/resources/one-sheeters/3Zmjyb5V35n11B' },
        { name: 'Keyword Search Advertising', path: '/resources/one-sheeters/3SDBYpyyvzk43m' },
        { name: 'YouTube Advertising', path: '/resources/one-sheeters/V9lRikmubWoP1M' },
        // { name: 'CRM Targeting', path: '/resources/one-sheeters/os-1' },
        // { name: 'Collateral Geofencing', path: '/resources/one-sheeters/os-2' },
      ],
    },
    {
      name: 'Vertical One Sheeter',
      path: '/resources/one-sheeters',
      links: [
        { name: 'Restaurant Customer Targeting ', path: '/resources/one-sheeters/QtuclYKmH7udbO' },
        { name: 'Fashion Retail Sales Package', path: '/resources/one-sheeters/qdDUbanNgLjs59' },
        { name: 'Jewelry Package', path: '/resources/one-sheeters/CkeFYxwWfD8cbl' },
        { name: 'Tax Services', path: '/resources/one-sheeters/2ZVOS2x4hxNImM' },
        { name: 'Auto Service & Repair', path: '/resources/one-sheeters/z6BEHwA8wBXum8' },
      ],
    },
    // {
    //   name: 'Case Studies',
    //   path: '/resources/case-studies',
    //   links: [
    //     { name: 'How effective are Drivers?', path: '/resources/case-studies/case-study-1' },
    //     { name: 'Woman\'s Sportswear', path: '/resources/case-studies/case-study-2' },
    //     { name: 'Consumer Technology Store', path: '/resources/case-studies/case-study-3' },
    //     { name: 'Quick Service Restaraunt', path: '/resources/case-studies/case-study-4' },
    //   ],
    // },
    // {
    //   name: 'One sheeters',
    //   path: '/resources/one-sheeters',
    //   links: [
    //     { name: 'CRM Targeting', path: '/resources/one-sheeters/os-1' },
    //     { name: 'Collateral geofencing', path: '/resources/one-sheeters/os-2' },
    //   ],
    // },
  ];

  constructor(private router: Router) {}

  getRouteAnimationState() {
    console.log('WOW', this.router.url);
    return this.router.url; // getRouteAnimationState(this.route);
  }

}
