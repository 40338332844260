import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { PORTAL_API_PATH } from '../../../constants';

export enum CampaignStatus {
  Live = 'Live',
  Scheduled = 'Scheduled',
  Ended = 'Ended',
}

export enum CampaignType {
  Display = 'Display',
  Video = 'Video',
  Search = 'Search',
  Unknown = '-',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  logoUrl?: string;
}

export interface Campaign {
  id: string;
  name: string;
  dashboardURL?: string;
  status: CampaignStatus;
  startDate: Date;
  endDate: Date;
  type: CampaignType;
  impressions: number;
  clicks: number;
  ctr: number;
  vtc: number;
  submittedBy?: User;
};

export interface CampaignMetric {
  impressions: number;
  clicks: number;
  ctr: number;
  vtc: number;
}

export interface CampaignDailyMetric extends CampaignMetric {
  date: string; // YYYY-MM-DD
}

export interface CampaignOkResponse {
  campaigns: Campaign[],
  data: CampaignDailyMetric[],
  total: CampaignMetric,
  next?: string,
}

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {
  constructor(private httpClient: HttpClient) { }

  async list(
    from: Date,
    to: Date,
    sortBy: string | null = null,
    sortOrder = SortOrder.Desc,
    filters?: any,
    next?: string,
  ) {
    const url = `${PORTAL_API_PATH}/campaigns`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: new HttpParams().appendAll({
        from_date: from.toISOString(),
        to_date: to.toISOString(),
      })
    };

    console.log('SORT?????', sortBy, sortOrder);
    if (sortBy) {
      httpOptions.params = httpOptions.params
        .append('sort_by', sortBy)
        .append('order_by', sortOrder);
    }

    if (filters) {
      httpOptions.params = httpOptions.params
        .append('filters', JSON.stringify(filters)); // TODO: fix stringify issue
    }

    if (next) {
      httpOptions.params = httpOptions.params
        .append('from', next);
    }

    const campaignSource$ = this.httpClient.get<CampaignOkResponse>(url, httpOptions);
    const response = await firstValueFrom(campaignSource$);

    return response;
  }
}
