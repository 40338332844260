import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { createWidget } from '@typeform/embed';

import { HasUnsavedChanges, unsavedChangesGuard } from '../../../../core/guards/unsaved-changes.guard';

@Component({
  selector: 'iml-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements AfterViewInit, HasUnsavedChanges {
  @ViewChild('formContainer') formContainer!: ElementRef;

  formId = 'r76seW';
  inProgress = false;
  @HostListener('window:beforeunload', ['$event']) handleBeforeUnload($event: any) {
    return (unsavedChangesGuard as any)(this);
  }

  ngAfterViewInit(): void {
    createWidget(this.formId, {
      container: this.formContainer.nativeElement,
      onStarted: () => this.inProgress = true,
      onSubmit: () => this.inProgress = false,
    });
  }

  hasUnsavedChanges(): boolean {
    return this.inProgress;
  }
}
