import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';

import { AUTH_PATH, CLIENT_ID, CODE_VERIFIER } from '../../../constants';

import { TokenService } from './token.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _redirectUrl!: string;
  private _loginPath = '/login';

  public set redirectUrl(url: string) { this._redirectUrl = url; }
  public get loginPath() { return this._loginPath; }

  public get token() { return this._token; }
  public get user() { return this._user; }

  constructor(private router: Router, private _token: TokenService, private _user: UserService) {}

  setCodeVerifier(codeVerifier: string, state: string) {
		sessionStorage.setItem(`${CODE_VERIFIER}-${state}`, codeVerifier);
	};

	getCodeVerifier(state: string): string {
		return sessionStorage.getItem(`${CODE_VERIFIER}-${state}`) as string;
	};

  authorize() {
		const codeVerifier = Date.now().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		const codeChallenge = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Hex);// shajs('sha256').update(codeVerifier).digest('HEX');
		const challengeMethod = 'S256';
		const redirectUri = window.location.href;
		const state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

		this.setCodeVerifier(codeVerifier, state);

    const params = new HttpParams()
      .set('client_id', CLIENT_ID)
      .set('code_challenge', codeChallenge)
      .set('challenge_method', challengeMethod)
      .set('redirect_uri', redirectUri)
      .set('state', state)
      // .set('provider', 'okta');

		window.location.assign(`${AUTH_PATH}/oauth/authorize?${params.toString()}`);
	};

  isLoggedIn() {
    return !this.token.hasExpired;
  }

  toRedirectUrl() {
    this.router.navigateByUrl(this._redirectUrl ?? '/');
  }

  toLogin() {
    this.router.navigateByUrl(this._loginPath);
  }

  async logout() {
    const { logout_url } = await this.token.revoke();

    if (logout_url) {
      window.location.assign(logout_url);
    } else {
      this.toLogin();
    }
  }
}
