@if (photo) {
  <div class="user-avatar">
    <div class="fpb-empty"></div>
    <div class="fpb-image" [style]="{'background-image': 'url(' + photo + ')'}"></div>
  </div>
} @else if (mode === 'initials') {
  <p class="user-initials">
    {{firstName ? firstName[0] : ''}}
    <!-- {{lastName ? lastName[0] : ''}} -->
  </p>
} @else {
  <div class="no-photo-cont fpb-primary--background-lighter">
    <img class="no-photo" src="/assets/images/iconmonstr-user-2.svg">
  </div>
}
