import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ScreenService } from './core/services/screen.service';

@Component({
  selector: 'iml-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(private screen: ScreenService) {}

  ngOnInit(): void {
    this.screen.init();
  }
}
