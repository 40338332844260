import { Component } from '@angular/core';

@Component({
  selector: 'iml-sidenav-container',
  standalone: true,
  imports: [],
  templateUrl: './sidenav-container.component.html',
  styleUrl: './sidenav-container.component.scss'
})
export class SidenavContainerComponent {

}
