import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private hasPageMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private pageMenuToggled$: Subject<void> = new Subject<void>();
  private pageMenuOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private mainMenuOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get hasPageMenu() { return this.hasPageMenu$; }
  public get pageMenuToggled() { return this.pageMenuToggled$; }
  public get isPageMenuOpened() { return this.pageMenuOpened$; }
  public get isMainMenuOpened() { return this.mainMenuOpened$; }

  constructor() { }

  togglePageMenu() {
    this.pageMenuToggled$.next();
  }
}
