import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { SidenavMenuComponent, SidenavMenuLink } from '../../core/components/sidenav/sidenav-menu/sidenav-menu.component';
import { SidenavContainerComponent } from '../../core/components/sidenav/sidenav-container/sidenav-container.component';
import { Router } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { fadeInOutPage } from '../../animations';
import { UserLogoComponent } from '../../shared/user-logo/user-logo.component';
import { AssetType, Creative, CreativeRequestStatus, CreativeRequestType, CreativesService } from './creatives.service';
import { SortOrder } from '../campaigns/campaigns.service';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
}

const USERS: User[] = [
  {
    id: 'u1',
    firstName: 'John',
    lastName: 'Smith',
    email: 'jsmith@wjrt.com',
    company: 'WJRT',
  },
  {
    id: 'u2',
    firstName: 'Antony',
    lastName: 'Martino',
    email: 'amartino@wjrt.com',
    company: 'WJRT',
  },
  {
    id: 'u3',
    firstName: 'Michael',
    lastName: 'Sandersonsky',
    email: 'msandersonsky@wjrt.com',
    company: 'WJRT',
  },
  {
    id: 'u4',
    firstName: 'Dmytro',
    lastName: 'Koshuba',
    email: 'dkoshuba@wjrt.com',
    company: 'WJRT',
  },
  {
    id: 'u5',
    firstName: 'Cleo',
    lastName: 'Superlonglastname',
    email: 'csuperlonglastname@wjrt.com',
    company: 'WJRT',
  },
  {
    id: 'u6',
    firstName: 'Stanislav',
    lastName: 'Yefremov',
    email: 'stas@insightmedialabs.com',
    company: 'IML',
  },
];

const CAMPAIGNS: Creative[] = [
  {
    id: 's1',
    name: 'IML - Test Creative Link 1 - Banner-Only',
    type: CreativeRequestType.Creative,
    date: new Date('Jun 7, 2024'),
    updatedAt: new Date(),
    status: CreativeRequestStatus.New,
    assets: [
      {
        id: 'a1',
        type: AssetType.Banners,
        name: 'Overhead Door of Eugene',
        url: 'http://banners.insightmedialabs.com/bcfwtq468/',
      },
      {
        id: 'a2',
        type: AssetType.Banners,
        name: 'Overhead Door of Eugene 2',
        url: 'http://banners.insightmedialabs.com/bcfwtq468/',
        isReleased: true,
      },
      {
        id: 'a3',
        type: AssetType.SODAs,
        name: 'IML | SODA - Diamond Jo Worth Casino (Linkedin)',
        url: 'https://bannerpreview.insightmedialabs.com/share/d/wr7g3gpzxj62?view=grid&size=de1zrny',
        isReleased: true,
      }
    ],
    submittedBy: USERS[5],
  },
  {
    id: 's2',
    name: 'IML - Test Creative Link 2 - Driver-Only',
    type: CreativeRequestType.RFP,
    date: new Date('Jun 7, 2024'),
    updatedAt: new Date(),
    status: CreativeRequestStatus.Released,
    assets: [
      {
        id: 'a1',
        type: AssetType.Driver,
        name: 'NIMBL NATION (Copy 1)',
        url: 'https://secureiml.com/P1hvjVgY0P9fgKD',
        isReleased: true,
      },
      {
        id: 'a2',
        type: AssetType.Driver,
        name: 'NIMBL NATION (Copy 2)',
        url: 'https://secureiml.com/P1hvjVkQ7xwF0zQ3',
      },
    ],
    submittedBy: USERS[5],
  },
  {
    id: 's3',
    name: 'IML - Test Creative Link 3 - Combined',
    type: CreativeRequestType.Creative,
    date: new Date('Jun 7, 2024'),
    updatedAt: new Date(),
    status: CreativeRequestStatus.Released,
    assets: [
      {
        id: 'a1',
        type: AssetType.Driver,
        name: '[Copy] Homecare for the 21st Century',
        url: 'https://secureiml.com/demo-elly-31-01-2022',
        isReleased: true,
      },
      {
        id: 'a2',
        type: AssetType.Driver,
        name: 'Rawlings (Copy 1)',
        url: 'https://secureiml.com/3lhRmoypWWkUpDyO',
        isReleased: true,
      },
      {
        id: 'a3',
        type: AssetType.Banners,
        name: 'Name for the test',
        url: 'http://banners.insightmedialabs.com/bcfwtq468/',
        isReleased: true,
      },
      {
        id: 'a4',
        type: AssetType.Banners,
        name: 'Avenel Peforming Arts',
        url: 'http://banners.insightmedialabs.com/b7xp2958h/',
        isReleased: true,
      }
    ],
    submittedBy: USERS[5],
  },
  // {
  //   id: 'c1',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Dec 12, 2023'),
  //   updatedAt: new Date(),
  //   status: CreativeRequestStatus.New,
  //   assets: [
  //     AssetType.Proposal
  //   ],
  //   // submittedBy: USERS[0],
  // },
  // {
  //   id: 'c2',
  //   name: 'WJRT - Voices for Children - Community Awareness - Dec23-Mar24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Dec 01, 2023'),
  //   updatedAt: new Date(),
  //   status: CreativeRequestStatus.New,
  //   assets: [
  //     AssetType.SODAs,
  //     AssetType.Banners,
  //   ],
  //   submittedBy: USERS[1],
  // },
  // {
  //   id: 'c3',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Nov 22, 2023'),
  //   updatedAt: new Date('Nov 22, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //     AssetType.SODAs,
  //   ],
  //   // submittedBy: USERS[1],
  // },
  // {
  //   id: 'c4',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Oct 09, 2023'),
  //   updatedAt: new Date('Nov 17, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [],
  //   submittedBy: USERS[1],
  // },
  // {
  //   id: 'c5',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Dec 27, 2023'),
  //   updatedAt: new Date('Dec 27, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.SODAs,
  //     AssetType.Banners,
  //   ],
  //   submittedBy: USERS[2],
  // },
  // {
  //   id: 'c6',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Dec 27, 2023'),
  //   updatedAt: new Date('Jan 17, 2024'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //   ],
  //   submittedBy: USERS[0],
  // },
  // {
  //   id: 'c7',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Nov 10, 2023'),
  //   updatedAt: new Date('Nov 10, 2023'),
  //   status: CreativeRequestStatus.Declined,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //     AssetType.SODAs,
  //   ],
  //   submittedBy: USERS[3],
  // },
  // {
  //   id: 'c8',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Oct 05, 2023'),
  //   updatedAt: new Date('Oct 05, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [],
  //   submittedBy: USERS[4],
  // },
  // {
  //   id: 'c9',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Aug 10, 2023'),
  //   updatedAt: new Date('Sept 15, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //   ],
  //   submittedBy: USERS[4],
  // },
  // {
  //   id: 'c10',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Oct 09, 2023'),
  //   updatedAt: new Date('Oct 21, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [],
  //   submittedBy: USERS[1],
  // },
  // {
  //   id: 'c11',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Dec 27, 2023'),
  //   updatedAt: new Date('Dec 27, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.SODAs,
  //     AssetType.Banners,
  //   ],
  //   submittedBy: USERS[2],
  // },
  // {
  //   id: 'c12',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Dec 27, 2023'),
  //   updatedAt: new Date('Dec 27, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //   ],
  //   submittedBy: USERS[0],
  // },
  // {
  //   id: 'c13',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Nov 10, 2023'),
  //   updatedAt: new Date('Nov 10, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //     AssetType.SODAs,
  //   ],
  //   submittedBy: USERS[3],
  // },
  // {
  //   id: 'c14',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Oct 05, 2023'),
  //   updatedAt: new Date('Oct 07, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [],
  //   submittedBy: USERS[4],
  // },
  // {
  //   id: 'c15',
  //   name: 'WJRT - Bishop International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Aug 10, 2023'),
  //   updatedAt: new Date('Aug 10, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //   ],
  //   submittedBy: USERS[4],
  // },
];

@Component({
  selector: 'iml-creatives',
  standalone: true,
  imports: [
    SidenavMenuComponent,
    SidenavContainerComponent,
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    UserLogoComponent,
  ],
  templateUrl: './creatives.component.html',
  styleUrl: './creatives.component.scss',
  animations: [fadeInOutPage],
})
export class CreativesComponent {
  CreativeRequestType = CreativeRequestType;
  CreativeRequestStatus = CreativeRequestStatus;
  AssetType = AssetType;

  opened = true;
  openInProgress = false;
  inProgress = false;

  isLoadingProgress = false;
  scrollPosition = 0;

  displayedColumns: string[] = [
    'submittedBy',
    'name',
    'type',
    'date',
    'status',
    'assets',
  ];
  dataSource = new MatTableDataSource([] as Creative[]); // CAMPAIGNS
  nextPage?: string;

  sortBy: string | null = null;
  sortOrder = SortOrder.Desc;
  from = new Date('2023-01-01');
  to = new Date();
  // range = new FormGroup({
  //   start: new FormControl<Date | null>(this.from),
  //   end: new FormControl<Date | null>(this.to),
  // });

  presets: SidenavMenuLink[] = [
    { name: 'All assets', path: './all' },
    { name: 'New assets', path: './new' },
    { name: 'RFPs', path: './rfps' },
    { name: 'Creatives', path: './creatives' },
    { name: 'Containing Banners', path: './banners' },
    { name: 'Containing Drivers', path: './drivers' },
  ];

  @ViewChild(MatSort) sort!: MatSort;

  constructor(private router: Router, private data: CreativesService) {}

  async ngAfterViewInit() {
    this.dataSource.sort = this.sort;

    // this.data.assets(
    this.isLoadingProgress = true;
    this.dataSource.data = await this.getAssets(this.from, this.to);
    this.isLoadingProgress = false;
  }

  async getAssets(
    from: Date,
    to: Date,
    sortBy: string | null = null,
    sortOrder = SortOrder.Desc,
  ) {
    this.inProgress = true;
    const { creatives, next } = await this.data.assets(from, to, sortBy, sortOrder, this.nextPage as any);
    this.inProgress = false;
    this.nextPage = next;

    return creatives;
  }

  async checkScroll(event: any) {
    const { scrollTop, scrollHeight, offsetHeight } = event.srcElement;
    this.scrollPosition = scrollTop;
    if (!this.inProgress) {
      const scrollValue = (scrollHeight - scrollTop) / offsetHeight;
      if (scrollValue < 1.2) {
        const assets = await this.getAssets(this.from, this.to);
        this.dataSource.data = this.dataSource.data.concat(assets);
      } else if (scrollValue < 2) {
        this.isLoadingProgress = true;
      } else {
        this.isLoadingProgress = false;
      }
    }
  }

  getRouteAnimationState() {
    console.log('WOW', this.router.url);
    return this.router.url; // getRouteAnimationState(this.route);
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    console.log('Sorting is happening...', sortState);
  }
}
