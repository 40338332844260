import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { AUTH_PATH, AUTH_USER } from '../../../constants';

export enum AccountType {
  Elly = 'ELLY',
  ClientPortal = 'CONSOLE',
  Both = 'BOTH',
}

export enum CompanyType {
  Agency = 'AGENCY',
  Station = 'STATION',
}

export interface Company {
  id: string;
  name: string;
  type: CompanyType;
  embedScope: boolean;
  parent?: Company;
  subsidiaries?: Company[];
  updatedAt: Date;
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  logoUrl: string;
  accountType: AccountType;
  company: Company;
  updatedAt: Date;
}

export interface UserResponse {
  user: User;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user!: User;

  constructor(private httpClient: HttpClient) {
    this.get();
  }

  set(user: User) {
		const json = JSON.stringify(user);

		this.user = user;
		sessionStorage.setItem(AUTH_USER, json);
	};

	get() {
		const json = sessionStorage.getItem(AUTH_USER);

		if (json !== null && typeof json !== 'undefined') {
      try {
        this.user = JSON.parse(json);
      } catch (error) {
        console.log('Failed to parse User');
      }
		}

		return this.user;
	};

	async request() {
    const url = `${AUTH_PATH}/api/userinfo`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const userInfoSource$ = this.httpClient.get<UserResponse>(url, httpOptions);
    const response = await firstValueFrom(userInfoSource$);

    return response;
	};
}
