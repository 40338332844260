<div class="iml-page-content-1" [@fadeInOutPage]="getRouteAnimationState()">
  <div class="iml-layout-container">
    <div style="text-align: center; padding-top: 10px;">
      <mat-chip-listbox class="iml-search-tabs" aria-label="Fish selection" style="display: inline-block;">
        <mat-chip-option selected (click)="selectSection('campaigns')">
          <div matChipAvatar class="iml-search-tab-count iml-color-text">{{dataSource.data.length}}</div>
          Campaigns
        </mat-chip-option>
        <mat-chip-option (click)="selectSection('assets')">
          <div matChipAvatar class="iml-search-tab-count iml-color-text">0</div>
          Assets
        </mat-chip-option>
        <mat-chip-option (click)="selectSection('forms')">
          <div matChipAvatar class="iml-search-tab-count iml-color-text">0</div>
          Request Forms
        </mat-chip-option>
        <mat-chip-option (click)="selectSection('resources')">
          <div matChipAvatar class="iml-search-tab-count iml-color-text">{{resources.length}}</div>
          Resources
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
    <div>
      @if (section === 'campaigns') {
        @if (inProgress) {
          <div>
            <mat-spinner [diameter]="30" style="margin-left: auto; margin-right: auto;"></mat-spinner>
          </div>
        }
        @if (dataSource.data.length === 0) {
          @if (!inProgress) {
            <div [@fadeInOutPage]="getSectionAnimationState()" style="padding: 10px; text-align: center;">No matching entries found.</div>
          }
        } @else {
          <div [@fadeInOutPage]="getSectionAnimationState()" class="iml-card">
            <div style="width: 100%; overflow-y: auto;">
              <style>
                .table-in-progress td {
                  opacity: 0.5;
                }
              </style>
              <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="demo-table" [class.table-in-progress]="inProgress">
                <ng-container matColumnDef="submittedBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email"> Submitted By </th>
                  <td mat-cell *matCellDef="let element">
                    @if (element.submittedBy) {
                      <!-- {{element.submittedBy?.email}} -->
                      <div class="user-container"
                        matTooltip="{{element.submittedBy.firstName}} {{element.submittedBy.lastName}} {{element.submittedBy.email}} | {{element.submittedBy.company}}">
                        <iml-user-logo
                          [id]="element.submittedBy.id"
                          [firstName]="element.submittedBy.firstName"
                          [lastName]="element.submittedBy.lastName"
                          [photo]="element.submittedBy.logoUrl"
                        />
                        <div class="user-name">
                          @if (element.submittedBy.lastName?.length > 10) {
                            {{element.submittedBy.firstName}}
                          } @else {
                            {{element.submittedBy.firstName[0]}}. {{element.submittedBy.lastName}}
                          }
                        </div>
                      </div>
                    } @else {
                      <div class="user-container">
                        <!-- <div class="user-warning">
                          <mat-icon inline>warning</mat-icon>
                        </div> -->
                        <div class="user-name">
                          <button mat-stroked-button>Assign</button>
                        </div>
                      </div>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> Campaign Name </th>
                  <td mat-cell *matCellDef="let element">
                    <a href="javascript:void(0);" class="demo-table-link">{{element.name}}</a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status </th>
                  <td mat-cell *matCellDef="let element">
                    @if (element.status === CampaignStatus.Live) {
                      <span class="my-badge full badge-green">Live</span>
                    } @else if (element.status === CampaignStatus.Scheduled) {
                      <span class="my-badge full badge-orange">Scheduled</span>
                    } @else {
                      <span class="my-badge full badge-red">Ended</span>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="startDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Start Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.startDate | date: 'MMM d, y'}} </td>
                </ng-container>
                <ng-container matColumnDef="endDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by end date"> End Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.endDate | date: 'MMM d, y'}} </td>
                </ng-container>
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Campaign Type </th>
                  <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>
                <ng-container matColumnDef="impressions">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Impressions </th>
                  <td mat-cell *matCellDef="let element"> {{element.impressions | number}} </td>
                </ng-container>
                <ng-container matColumnDef="clicks">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Clicks </th>
                  <td mat-cell *matCellDef="let element"> {{element.clicks | number}} </td>
                </ng-container>
                <ng-container matColumnDef="ctr">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> CTR </th>
                  <td mat-cell *matCellDef="let element"> {{element.ctr | number: '1.2-2'}}% </td>
                </ng-container>
                <ng-container matColumnDef="vtc">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> VTC </th>
                  <td mat-cell *matCellDef="let element"> {{element.vtc | number: '1.2-2'}}% </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        }
      } @else if (section === 'assets') {
        <!-- <div class="iml-card">
          <div style="width: 100%; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="demo-table">
              <ng-container matColumnDef="submittedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email"> Submitted By </th>
                <td mat-cell *matCellDef="let element">
                  @if (element.submittedBy) {
                    <div class="user-container"
                      matTooltip="{{element.submittedBy.firstName}} {{element.submittedBy.lastName}} {{element.submittedBy.email}} | {{element.submittedBy.company}}">
                      <iml-user-logo
                        [id]="element.submittedBy.id"
                        [firstName]="element.submittedBy.firstName"
                        [lastName]="element.submittedBy.lastName"
                        [photo]="element.submittedBy.logoUrl"
                      />
                      <div class="user-name">
                        @if (element.submittedBy.lastName?.length > 10) {
                          {{element.submittedBy.firstName}}
                        } @else {
                          {{element.submittedBy.firstName[0]}}. {{element.submittedBy.lastName}}
                        }
                      </div>
                    </div>
                  } @else {
                    <div class="user-container">
                      <div class="user-name">
                        <button mat-stroked-button>Assign</button>
                      </div>
                    </div>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> Project Name </th>
                <td mat-cell *matCellDef="let element">
                  <a href="javascript:void(0);" class="demo-table-link" [innerHTML]="element.name"></a>
                </td>
              </ng-container>
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Request Type </th>
                <td mat-cell *matCellDef="let element">
                  @if (element.status === CreativeRequestType.Creative) {
                    <span class="my-badge full badge-iml-green">Creative</span>
                  } @else {
                    <span class="my-badge full badge-iml-green">RFP</span>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Submitted At </th>
                <td mat-cell *matCellDef="let element"> {{element.date | date: 'MMM d, y'}} </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status </th>
                <td mat-cell *matCellDef="let element">
                  @if (element.status === CreativeRequestStatus.New) {
                    <span class="my-badge full badge-gray">New</span>
                  } @else if (element.status === CreativeRequestStatus.Released) {
                    <span class="my-badge full badge-green">Released</span>
                  } @else {
                    <span class="my-badge full badge-red">Declined</span>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Updated At </th>
                <td mat-cell *matCellDef="let element"> {{element.date | date: 'MMM d, y'}} </td>
              </ng-container>
              <ng-container matColumnDef="assets">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by start date"> Assets </th>
                <td mat-cell *matCellDef="let element">
                  @for (asset of element.assets; track $index) {
                    <span class="my-badge badge-item badge-iml-blue">{{asset}}</span>
                  }
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div> -->
      } @else if (section === 'forms') {
        <div class="iml-search-list">
          <a routerLink="/submissions/requests/creative" class="iml-search-entry">
            <div class="iml-search-entry-text mat-body">
              3 &rarr; What is the <b>driver</b>'s name?
            </div>
            <div class="iml-search-entry--footer">
              <div class="iml-search-entry--footer-text mat-caption">
                Requests > Submit Creative
              </div>
              <div class="iml-search-entry--footer-page mat-caption">Question 3</div>
            </div>
          </a>
          <a routerLink="/submissions/orders/newsdriver-campaign" class="iml-search-entry">
            <div class="iml-search-entry-text mat-body">
              4 &rarr; <b>Driver</b> name <br>
              Example: HP announces new...
            </div>
            <div class="iml-search-entry--footer">
              <div class="iml-search-entry--footer-text mat-caption">
                Orders > News<b>Driver</b> Campaign
              </div>
              <div class="iml-search-entry--footer-page mat-caption">Question 4</div>
            </div>
          </a>
        </div>
      } @else {
        @if (inProgress) {
          <div>
            <mat-spinner [diameter]="30" style="margin-left: auto; margin-right: auto;"></mat-spinner>
          </div>
        }
        @if (resources.length === 0) {
          @if (!inProgress) {
            <div [@fadeInOutPage]="getSectionAnimationState()" style="padding: 10px; text-align: center;">No matching entries found.</div>
          }
        } @else {
          <div [@fadeInOutPage]="getSectionAnimationState()" class="iml-search-list">
            @for (resource of resources; track $index) {
              <a [routerLink]="resource.url" class="iml-search-entry">
                <div class="iml-search-entry-text mat-body" [innerHTML]="sanitize(resource.content)"></div>
                <div class="iml-search-entry--footer">
                  <div class="iml-search-entry--footer-text mat-caption">
                    @if (resource.category) {
                      <span [innerHTML]="sanitize(resource.category)"></span>
                      <span> > </span>
                    }
                    <span [innerHTML]="sanitize(resource.name)"></span>
                  </div>
                  @if (resource.page) {
                    <div class="iml-search-entry--footer-page mat-caption">Page {{resource.page}}</div>
                  }
                </div>
              </a>
            }
            <!-- <a routerLink="/resources/case-studies/case-study-1" class="iml-search-entry">
              <div class="iml-search-entry-text mat-body">
                ...Google, Facebook, and other Ad Networks reward advertisers with landing pages and <b>driver</b>s that follow their quality and creative guidelines, and penalize advertisers that do not. A campaign's landing page can actually determine as much as 30% of its "Quality Score", which is the major factor in determining a campaign's CPM and CPC.
              </div>
              <div class="iml-search-entry--footer">
                <div class="iml-search-entry--footer-text mat-caption">
                  Case Studies > How effective are <b>Driver</b>s?
                </div>
                <div class="iml-search-entry--footer-page mat-caption">Page 1</div>
              </div>
            </a>
            <a routerLink="/resources/case-studies/case-study-1" class="iml-search-entry">
              <div class="iml-search-entry-text mat-body">
                ...to learn more about the benefits of using a <b>Driver</b>? Give us a shout! We're constantly rolling out new features to help your campaign's increase their efficiency and campaign performance.
              </div>
              <div class="iml-search-entry--footer">
                <div class="iml-search-entry--footer-text mat-caption">
                  Case Studies > How effective are <b>Driver</b>s?
                </div>
                <div class="iml-search-entry--footer-page mat-caption">Page 4</div>
              </div>
            </a>
            <a routerLink="/resources/case-studies/case-study-1" class="iml-search-entry">
              <div class="iml-search-entry-text mat-body">
                ...location technology and data insights we built audiences based on historical shopping behaviours and loyalty, and looked at consumers visiting price comparison and mortgage calculator sites. Location targeting was used to uncover and engage with consumers in close proximity to key stores. High-impact interstitials and <b>Driver</b>s of key products were used, along with mapping and directions, to make it convenient for people...
              </div>
              <div class="iml-search-entry--footer">
                <div class="iml-search-entry--footer-text mat-caption">
                  Case Studies > Consumer Technology Store
                </div>
                <div class="iml-search-entry--footer-page mat-caption">Page 3</div>
              </div>
            </a>
            <a routerLink="/resources/case-studies/case-study-1" class="iml-search-entry">
              <div class="iml-search-entry-text mat-body">
                ...audiences, based upon the gathered device IDs, will now receive the advertiser's ads as they browse content and <b>Driver</b>s during the course of the campaign. Insight Media Labs CRM targeting provides the most customized delivery because it leverages advertisers CRM data.
              </div>
              <div class="iml-search-entry--footer">
                <div class="iml-search-entry--footer-text mat-caption">
                  One sheeters > CRM Targeting
                </div>
                <div class="iml-search-entry--footer-page mat-caption">Page 1</div>
              </div>
            </a> -->
          </div>
        }
      }
    </div>
  </div>
</div>
