import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'iml-login',
  standalone: true,
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private auth: AuthService) {}

  ngOnInit(): void {
    if (this.auth.isLoggedIn()) {
      const user = this.auth.user.get();

      if (user) {
        this.router.navigate(['/']);
        return;
      }
    }

    // TODO: polish the logic
    this.authorize();
  }

  async authorize() {
    const code = this.route.snapshot.queryParamMap.get('code');
    const state = this.route.snapshot.queryParamMap.get('state');

    if (!state || !code) {
      return this.auth.authorize();
    }

    try {
      const codeVerifier = this.auth.getCodeVerifier(state);
      const token = await this.auth.token.request(code, codeVerifier);
      this.auth.token.set(token);

      const { user } = await this.auth.user.request();
      this.auth.user.set(user);

      // Navigating to previously used or main url
      this.auth.toRedirectUrl();
    } catch (error) {
      console.log('Authorization error', error);
      // NotifyService.notify('Failed to login. Please, try again later.', true);
      console.log('Failed to login. Please, try again later.');

      // Reattempting again
      setTimeout(() => {
        this.auth.authorize();
      }, 300000);
    }
  }
}
