import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  // Setting redirect url
  if (!router.url.startsWith(auth.loginPath)) {
    auth.redirectUrl = router.url;
  }

  return auth.isLoggedIn() ? true : router.createUrlTree([auth.loginPath]);
};
