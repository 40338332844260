<mat-sidenav-container>
  <mat-sidenav #sidenav [(opened)]="opened" [mode]="screen.isDesktop.value ? 'side' : 'over'" opened style="background: none; border: none;">
    <iml-sidenav-container>
      <iml-sidenav-menu [links]="links" />
    </iml-sidenav-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="page-container" [@fadeInOutPage]="getRouteAnimationState()" style="height: 100vh;">
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
