<mat-toolbar style="background-color: rgba(250, 250, 250, 0.7); backdrop-filter: blur(10px);"
  [class.iml-mobile]="screen.isMobile.value">
  @if (screen.isMobile.value) {
    <button mat-icon-button (click)="openMenu()" class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon fontSet="material-symbols-outlined">menu</mat-icon>
    </button>
  }
  <a routerLink="/" [style.margin-left.px]="screen.isMobile.value ? 5 : 0">
    <img src="assets/images/iml-logo-stacked.png" style="margin-top: 8px;" [style.width.px]="screen.isMobile.value ? 36 : 60" />
  </a>
  <span style="margin-left: 15px;">Client Portal</span>
  @if (!screen.isMobile.value) {
    <div style="width: 60%; margin-bottom: -17px; padding-left: 20px;">
      <form [formGroup]="searchForm" (ngSubmit)="search()">
        <mat-form-field appearance="outline" style="width: 100%;" class="iml-search-input">
          <input matInput placeholder="Search..." formControlName="query">
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </form>
    </div>
  }
  <span class="spacer"></span>
  @if (screen.isMobile.value) {
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon fontSet="material-symbols-outlined">search</mat-icon>
    </button>
  }
  <div>
    <iml-user-logo class="user-logo" matTooltip="{{user.firstName}} {{user.lastName}} {{user.email}}"
      [id]="user.id"
      [firstName]="user.firstName"
      [lastName]="user.lastName"
      [photo]="user.logoUrl"
      [size]="30"
      [matMenuTriggerFor]="menu"
    />
    <!-- <iml-user-logo
      [id]="element.submittedBy.id"
      [firstName]="element.submittedBy.firstName"
      [lastName]="element.submittedBy.lastName"
      [photo]="element.submittedBy.logoUrl"
    /> -->
    <mat-menu #menu="matMenu" class="user-menu">
      <div style="width: 280px; display: flex; flex-direction: column; align-items: center; padding: 20px; padding-top: 15px;">
        <iml-user-logo
          [id]="user.id"
          [firstName]="user.firstName"
          [lastName]="user.lastName"
          [photo]="user.logoUrl"
          [size]="60"
          [matMenuTriggerFor]="menu"
        />
        <div class="mat-h3" style="margin-top: 20px; font-weight: 600; margin-bottom: 0; line-height: 0.9;">
          {{user.firstName}} {{user.lastName}}</div>
        <div class="mat-h4" style="margin-bottom: 0; font-weight: 400;">{{user.email}}</div>
        @if (user.company) {
          <div class="mat-h4" style="margin-bottom: 0; font-weight: 400;">{{user.company.name}}</div>
        }
        <a href="javascript:void();" mat-stroked-button style="margin-top: 15px; border-radius: 20px;">Manage Your Account</a>
      </div>
      <mat-divider></mat-divider>
      <div style="padding: 20px; padding-bottom: 15px; text-align: center;">
        <button mat-stroked-button color="primary" (click)="logout()">Logout from your account</button>
      </div>
    </mat-menu>
  </div>
  <!-- <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon>account_circle</mat-icon>
  </button> -->
</mat-toolbar>
