import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { AUTH_PATH, AUTH_TOKEN, CLIENT_ID } from '../../../constants';

export enum AuthorizationTokenType {
  Bearer = 'Bearer',
  Unknown = 'Unknown'
}

export interface ApiAuthorizationToken {
  access_token: string;
  expires_in: number;
  token_type: string;
}

export interface AuthorizationToken {
  accessToken: string;
  expiresIn: number;
  type: AuthorizationTokenType;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private token!: AuthorizationToken;

  public get accessToken() {
    return this.token?.accessToken ?? null;
  }

  public get expiresIn() {
    return this.token?.expiresIn ?? null;
  }

  public get hasExpired() {
    return this.expiresIn > 0 ? this.token.expiresIn < Date.now() : true;
  }

  constructor(private httpClient: HttpClient) {
    this.get();
  }

  set(token: ApiAuthorizationToken) {
    const { access_token: accessToken, expires_in: expiresIn, token_type: type } = token;

		this.token = {
      accessToken,
      expiresIn: Date.now() + (expiresIn - 120) * 1000, // 24h - 2 minutes just in case
      type: type === 'Bearer' ? AuthorizationTokenType.Bearer : AuthorizationTokenType.Unknown,
    };

		sessionStorage.setItem(AUTH_TOKEN, JSON.stringify(this.token));
	}

	get() {
		const token = sessionStorage.getItem(AUTH_TOKEN);

		if (token !== null && typeof token !== 'undefined') {
      try {
        this.token = JSON.parse(token);
      } catch (error) {
        console.log('Failed to parse auth token');
      }
		}

		return this.token;
	}

	remove() {
		sessionStorage.removeItem(AUTH_TOKEN);

    this.token = undefined as any; // TODO: think of a better solution
	}

  async request(code: string, codeVerifier: string) {
    const url = `${AUTH_PATH}/oauth/token`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

		const data = {
			client_id: CLIENT_ID,
			code_verifier: codeVerifier,
			code: code
		};

    const tokenSource$ = this.httpClient.post<any>(url, data, httpOptions);
    const response = await firstValueFrom(tokenSource$);

    return response;
	};

  async revoke() {
		const accessToken = this.accessToken;

		if (accessToken && !this.hasExpired) {
      const url = `${AUTH_PATH}/oauth/token/revoke`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      try {
        const tokenSource$ = this.httpClient.post<any>(url, {}, httpOptions);

        const response = await firstValueFrom(tokenSource$);
        this.remove();

        return response;
      } catch (error) {
        console.log('ERROR', error);
      }
		}

    this.remove();
	};
}
