import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';

import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MenuService } from '../../core/services/menu.service';
import { fadeInOutPage, getRouteAnimationState } from '../../animations';
import { UserLogoComponent } from '../../shared/user-logo/user-logo.component';

import { User } from '../creatives/creatives.component';
import { Creative, CreativeRequestType, CreativeRequestStatus, AssetType } from '../creatives/creatives.service';
import { CommonModule } from '@angular/common';
import { SearchResource, SearchService } from './search.service';
import { Campaign, CampaignStatus, SortOrder } from '../campaigns/campaigns.service';
import { DomSanitizer } from '@angular/platform-browser';

const USERS: User[] = [
  {
    id: 'u1',
    firstName: 'John',
    lastName: 'Smith',
    email: 'jsmith@wjrt.com',
    company: 'WJRT',
  },
  {
    id: 'u2',
    firstName: 'Antony',
    lastName: 'Martino',
    email: 'amartino@wjrt.com',
    company: 'WJRT',
  },
  {
    id: 'u3',
    firstName: 'Michael',
    lastName: 'Sandersonsky',
    email: 'msandersonsky@wjrt.com',
    company: 'WJRT',
  },
  {
    id: 'u4',
    firstName: 'Dmytro',
    lastName: 'Koshuba',
    email: 'dkoshuba@wjrt.com',
    company: 'WJRT',
  },
  {
    id: 'u5',
    firstName: 'Cleo',
    lastName: 'Superlonglastname',
    email: 'csuperlonglastname@wjrt.com',
    company: 'WJRT',
  },
];

const CAMPAIGNS: Creative[] = [
  // {
  //   id: 'c1',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Dec 12, 2023'),
  //   updatedAt: new Date(),
  //   status: CreativeRequestStatus.New,
  //   assets: [
  //     AssetType.Proposal
  //   ],
  //   // submittedBy: USERS[0],
  // },
  // {
  //   id: 'c2',
  //   name: 'WJRT - Voices for <b>Driver</b>s - Community Awareness - Dec23-Mar24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Dec 01, 2023'),
  //   updatedAt: new Date(),
  //   status: CreativeRequestStatus.New,
  //   assets: [
  //     AssetType.SODAs,
  //     AssetType.Banners,
  //   ],
  //   submittedBy: USERS[1],
  // },
  // {
  //   id: 'c3',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Nov 22, 2023'),
  //   updatedAt: new Date('Nov 22, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Banners,
  //     AssetType.Driver,
  //     AssetType.SODAs,
  //   ],
  //   // submittedBy: USERS[1],
  // },
  // {
  //   id: 'c4',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Oct 09, 2023'),
  //   updatedAt: new Date('Nov 17, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [],
  //   submittedBy: USERS[1],
  // },
  // {
  //   id: 'c5',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Dec 27, 2023'),
  //   updatedAt: new Date('Dec 27, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.SODAs,
  //     AssetType.Banners,
  //   ],
  //   submittedBy: USERS[2],
  // },
  // {
  //   id: 'c6',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Dec 27, 2023'),
  //   updatedAt: new Date('Jan 17, 2024'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //   ],
  //   submittedBy: USERS[0],
  // },
  // {
  //   id: 'c7',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Nov 10, 2023'),
  //   updatedAt: new Date('Nov 10, 2023'),
  //   status: CreativeRequestStatus.Declined,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //     AssetType.SODAs,
  //   ],
  //   submittedBy: USERS[3],
  // },
  // {
  //   id: 'c8',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Oct 05, 2023'),
  //   updatedAt: new Date('Oct 05, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [],
  //   submittedBy: USERS[4],
  // },
  // {
  //   id: 'c9',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Aug 10, 2023'),
  //   updatedAt: new Date('Sept 15, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //   ],
  //   submittedBy: USERS[4],
  // },
  // {
  //   id: 'c10',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Oct 09, 2023'),
  //   updatedAt: new Date('Oct 21, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [],
  //   submittedBy: USERS[1],
  // },
  // {
  //   id: 'c11',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Dec 27, 2023'),
  //   updatedAt: new Date('Dec 27, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.SODAs,
  //     AssetType.Banners,
  //   ],
  //   submittedBy: USERS[2],
  // },
  // {
  //   id: 'c12',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Aug23-Dec24 - Display',
  //   type: CreativeRequestType.Creative,
  //   date: new Date('Dec 27, 2023'),
  //   updatedAt: new Date('Dec 27, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //   ],
  //   submittedBy: USERS[0],
  // },
  // {
  //   id: 'c13',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Nov 10, 2023'),
  //   updatedAt: new Date('Nov 10, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //     AssetType.SODAs,
  //   ],
  //   submittedBy: USERS[3],
  // },
  // {
  //   id: 'c14',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Oct 05, 2023'),
  //   updatedAt: new Date('Oct 07, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [],
  //   submittedBy: USERS[4],
  // },
  // {
  //   id: 'c15',
  //   name: 'WJRT - <b>Driver</b> International Airport - Destination Flights - Dec23-Apr24 - Display',
  //   type: CreativeRequestType.RFP,
  //   date: new Date('Aug 10, 2023'),
  //   updatedAt: new Date('Aug 10, 2023'),
  //   status: CreativeRequestStatus.Released,
  //   assets: [
  //     AssetType.Banners,
  //     AssetType.Drivers,
  //   ],
  //   submittedBy: USERS[4],
  // },
];

@Component({
  selector: 'iml-search',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatChipsModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    UserLogoComponent,
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  animations: [fadeInOutPage],
})
export class SearchComponent implements OnInit, AfterViewInit {
  section = 'campaigns';
  inProgress = false;

  CampaignStatus = CampaignStatus;
  CreativeRequestType = CreativeRequestType;
  CreativeRequestStatus = CreativeRequestStatus;
  AssetType = AssetType;
  displayedColumns: string[] = [
    'submittedBy',
    'name',
    'status',
    'startDate',
    'endDate',
    'type',
    'impressions',
    'clicks',
    'ctr',
    'vtc',
  ];
  dataSource = new MatTableDataSource<Campaign>(); // CAMPAIGNS);
  resources: SearchResource[] = [];

  // displayedColumns: string[] = [
  //   'submittedBy',
  //   'name',
  //   'type',
  //   'date',
  //   'status',
  //   'updatedAt',
  //   'assets',
  // ];
  // dataSource = new MatTableDataSource(CAMPAIGNS);

  query!: string;
  sortBy: string | null = null;
  sortOrder = SortOrder.Desc;
  from = new Date('2022-01-01');
  to = new Date();
  // range = new FormGroup({
  //   start: new FormControl<Date | null>(this.from),
  //   end: new FormControl<Date | null>(this.to),
  // });

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private menu: MenuService,
    private service: SearchService,
  ) {}

  ngOnInit(): void {
    this.menu.isPageMenuOpened.next(false);
    this.route.queryParams
      .subscribe(async (params: Params) => {
        console.log('params', params['q']);
        this.query = params['q'];
        this.inProgress = true;

        const { campaigns, resources } = await this.fetchSearchResults(this.query, this.from, this.to, this.sortBy, this.sortOrder);
        this.dataSource.data = campaigns;
        this.resources = resources;
        this.inProgress = false;
      }
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  async fetchSearchResults(
    query: string,
    from: Date,
    to: Date,
    sortBy: string | null = null,
    sortOrder = SortOrder.Desc,
  ) {
    try {
      const [response, resources] = await Promise.all([
        this.service.campaigns(query, from, to, sortBy, sortOrder),
        this.service.resources(query),
      ]);
      console.log('SEARCH CAMPAIGNS', response);

      return {
        campaigns: response.campaigns,
        resources,
      };
    } catch (error) {
      console.log('SEARCH ERROR', error);

      throw error;
    }
  }

  sanitize(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  async sortData(sortState: Sort) {
    const sortMap: { [key: string]: string } = {
      submittedBy: 'submitted_by',
      startDate: 'start_date',
      endDate: 'end_date',
    };
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    console.log('Sorting is happening...', sortState);
    let campaigns: Campaign[];

    this.inProgress = true;
    if (sortState.direction === SortOrder.Asc) {
      this.sortBy = sortMap[sortState.active] ?? sortState.active;
      this.sortOrder = SortOrder.Asc;
      const { campaigns: fetchedCampaigns } = await this.fetchSearchResults(this.query, this.from, this.to, this.sortBy, this.sortOrder);
      campaigns = fetchedCampaigns;
    } else if (sortState.direction === SortOrder.Desc) {
      this.sortBy = sortMap[sortState.active] ?? sortState.active;
      this.sortOrder = SortOrder.Desc;
      const { campaigns: fetchedCampaigns } = await this.fetchSearchResults(this.query, this.from, this.to, this.sortBy, this.sortOrder);
      campaigns = fetchedCampaigns;
    } else {
      const { campaigns: fetchedCampaigns } = await this.fetchSearchResults(this.query, this.from, this.to);
      campaigns = fetchedCampaigns;
    }

    console.log('CAMPAIGNS', campaigns);
    if (campaigns) {
      this.dataSource.data = campaigns;
    }
    this.inProgress = false;
  }

  selectSection(section: string) {
    this.section = section;
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    console.log('Sorting is happening...', sortState);
  }

  getRouteAnimationState() {
    // console.log('WOW', this.route.snapshot);
    return this.router.url; // getRouteAnimationState(this.route);
  }

  getSectionAnimationState() {
    return this.section;
  }
}
