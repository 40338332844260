<!-- <mat-list role="list"> -->
@if (mode === 'full') {
  <div style="margin-top: -7px; padding-left: 7px; margin-bottom: 15px;">
    <button mat-icon-button (click)="closeMenu()" class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon fontSet="material-symbols-outlined">menu_opened</mat-icon>
    </button>
  </div>
  @if (sublinks.length === 0) {
    <div @fadeInOutSectionModal>
      @for (link of links; track link.name) {
        @if (link.isExternal) {
          <div>
            <a mat-button target="_blank" [href]="link.path" (click)="closeMenu()" class="sidenav-item">
              <div class="iml-link-content">
                @if (link.icon) {
                  <mat-icon fontSet="material-symbols-outlined">{{link.icon}}</mat-icon>
                }
                <span class="iml-button-text">{{link.name}}</span>
              </div>
            </a>
          </div>
        } @else if(link.links && link.links.length > 0) {
          <div>
            <a [routerLink]="link.path" routerLinkActive="active" #rla="routerLinkActive"></a>
            <button mat-button (click)="openSubMenu($event, link.links);" class="sidenav-item iml-full-width" [class.active]="rla.isActive">
              <div class="iml-link-content">
                @if (link.icon) {
                  <mat-icon fontSet="material-symbols-outlined">{{link.icon}}</mat-icon>
                }
                <span class="iml-button-text">{{link.name}}</span>
                <mat-icon fontSet="material-symbols-outlined">arrow_forward</mat-icon>
              </div>
            </button>
          </div>
        } @else {
          <div>
            <a mat-button [routerLink]="link.path" routerLinkActive="active" (click)="closeMenu()" class="sidenav-item iml-full-width">
              <div class="iml-link-content">
                @if (link.icon) {
                  <mat-icon fontSet="material-symbols-outlined">{{link.icon}}</mat-icon>
                }
                <span class="iml-button-text">{{link.name}}</span>
              </div>
            </a>
          </div>
        }
      }
    </div>
  } @else {
    <div @fadeInOutSubsectionModal>
      <div>
        <button mat-button (click)="backToMainMenu()" class="sidenav-item iml-full-width">
          <div class="iml-link-content">
            <mat-icon fontSet="material-symbols-outlined">arrow_back</mat-icon>
            <span class="iml-button-text">Main menu</span>
          </div>
        </button>
      </div>
      @for (link of sublinks; track link.name) {
        <div style="padding-left: 34px;">
          @if (link.links && link.links.length > 0) {
            <mat-expansion-panel [expanded]="link.isExpanded">
              <mat-expansion-panel-header class="sidenav-item">
                @if (link.path) {
                  @if (link.isExternal) {
                    <a mat-button target="_blank" [href]="link.path" (click)="closeMenu()">{{link.name}}</a>
                  } @else {
                    <a mat-button [routerLink]="link.path" routerLinkActive="active" (click)="closeMenu()">{{link.name}}</a>
                  }
                } @else {
                  <button mat-button (click)="closeMenu()">{{link.name}}</button>
                }
              </mat-expansion-panel-header>
              <mat-list role="list">
                @for (sublink of link.links; track sublink.name) {
                  <mat-list-item role="listitem" class="sidenav-subitem">
                    @if (sublink.isExternal) {
                      <a mat-button target="_blank" [href]="sublink.path" (click)="closeMenu()" class="sidenav-item">
                        {{sublink.name}}
                      </a>
                    } @else {
                      <a mat-button [routerLink]="sublink.path" routerLinkActive="active" (click)="closeMenu()" class="sidenav-item">
                        {{sublink.name}}
                      </a>
                    }
                  </mat-list-item>
                }
              </mat-list>
            </mat-expansion-panel>
          } @else {
            <div>
              @if (link.isExternal) {
                <a mat-button target="_blank" [href]="link.path" class="sidenav-item">{{link.name}}</a>
              } @else {
                <a mat-button [routerLink]="link.path" routerLinkActive="active" class="sidenav-item">{{link.name}}</a>
              }
            </div>
          }
        </div>
      }
    </div>
  }
} @else {
  <div>
    @for (link of links; track link.name) {
      <!-- <mat-list-item role="listitem"> -->
        @if (link.links && link.links.length > 0) {
          <mat-expansion-panel [expanded]="link.isExpanded">
            <mat-expansion-panel-header class="sidenav-item">
              @if (link.path) {
                @if (link.isExternal) {
                  <a mat-button target="_blank" [href]="link.path" (click)="closeMenu()">{{link.name}}</a>
                } @else {
                  <a mat-button [routerLink]="link.path" routerLinkActive="active" (click)="closeMenu()">{{link.name}}</a>
                }
              } @else {
                <button mat-button (click)="closeMenu()">{{link.name}}</button>
              }
            </mat-expansion-panel-header>
            <mat-list role="list">
              @for (sublink of link.links; track sublink.name) {
                <mat-list-item role="listitem" class="sidenav-subitem">
                  @if (sublink.isExternal) {
                    <a mat-button target="_blank" [href]="sublink.path" (click)="closeMenu()" class="sidenav-item">
                      {{sublink.name}}
                    </a>
                  } @else {
                    <a mat-button [routerLink]="sublink.path" routerLinkActive="active" (click)="closeMenu()" class="sidenav-item">
                      {{sublink.name}}
                    </a>
                  }
                </mat-list-item>
              }
            </mat-list>
          </mat-expansion-panel>
        } @else {
          <div>
            @if (link.isExternal) {
              <a mat-button target="_blank" [href]="link.path" (click)="closeMenu()" class="sidenav-item">{{link.name}}</a>
            } @else {
              <a mat-button [routerLink]="link.path" routerLinkActive="active" (click)="closeMenu()" class="sidenav-item">{{link.name}}</a>
            }
          </div>
        }
      <!-- </mat-list-item> -->
    }
  </div>
}
<!-- </mat-list> -->

<!-- <p class="mat-body-strong" style="padding: 15px; padding-bottom: 0;">Requests</p>
<mat-list role="list">
  <mat-list-item role="listitem">
    <a mat-button routerLink="./requests/rfp" routerLinkActive="active">Submit RFP</a>
  </mat-list-item>
  <mat-list-item role="listitem">
    <a mat-button routerLink="./requests/creative" routerLinkActive="active">Submit Creative</a>
  </mat-list-item>
  <mat-list-item role="listitem">
    <a mat-button routerLink="./requests/dashboard" routerLinkActive="active">Request Dashboard</a>
  </mat-list-item>
</mat-list>
<p class="mat-body-strong" style="padding: 15px; padding-bottom: 0;">Orders</p>
<mat-list role="list">
  <mat-list-item role="listitem">
    <a mat-button routerLink="./orders/display-campaign" routerLinkActive="active">Display Campaign</a>
  </mat-list-item>
  <mat-list-item role="listitem">
    <a mat-button routerLink="./orders/video-campaign" routerLinkActive="active">Video Campaign</a>
  </mat-list-item>
  <mat-list-item role="listitem">
    <a mat-button routerLink="./orders/audio-campaign" routerLinkActive="active">Audio Campaign</a>
  </mat-list-item>
  <mat-list-item role="listitem">
    <a mat-button routerLink="./orders/newsdriver-campaign" routerLinkActive="active">NewsDriver Campaign</a>
  </mat-list-item>
  <mat-list-item role="listitem">
    <a mat-button routerLink="./orders/search-campaign" routerLinkActive="active">Search Campaign</a>
  </mat-list-item>
  <mat-list-item role="listitem">
    <a mat-button routerLink="./orders/dooh-campaign" routerLinkActive="active">DOOH Campaign</a>
  </mat-list-item>
</mat-list> -->
