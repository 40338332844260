import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, JsonPipe } from '@angular/common';

import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, ChildrenOutletContexts, Router } from '@angular/router';

import { fadeInOutPage, getRouteAnimationState } from '../../animations';
import { SidenavMenuComponent, SidenavMenuLink } from '../../core/components/sidenav/sidenav-menu/sidenav-menu.component';
import { SidenavContainerComponent } from '../../core/components/sidenav/sidenav-container/sidenav-container.component';
import { UserLogoComponent } from '../../shared/user-logo/user-logo.component';
import { ScreenService } from '../../core/services/screen.service';
import { Subscription } from 'rxjs';
import { MenuService } from '../../core/services/menu.service';
import { Campaign, CampaignDailyMetric, CampaignMetric, CampaignStatus, CampaignType, CampaignsService, SortOrder, User } from './campaigns.service';
import { ChartConfiguration, ChartDataset, ChartType } from 'chart.js';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { debounceTime } from 'rxjs';
import { CampaignFilters } from './interfaces';
import { CampaignsFiltersComponent } from './campaigns-filters/campaigns-filters.component';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'iml-campaigns',
  standalone: true,
  imports: [
    CommonModule,
    SidenavMenuComponent,
    SidenavContainerComponent,
    NgChartsModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    UserLogoComponent,
    CampaignsFiltersComponent,
  ],
  providers: [
    MatDatepickerModule,
    DatePipe,
    DecimalPipe,
    provideNativeDateAdapter(),
  ],
  templateUrl: './campaigns.component.html',
  styleUrl: './campaigns.component.scss',
  animations: [fadeInOutPage],
})
export class CampaignsComponent implements OnInit, AfterViewInit, OnDestroy {
  opened = true;
  openInProgress = false;
  inProgress = false;

  isLoadingProgress = false;
  scrollPosition = 0;

  CampaignStatus = CampaignStatus;
  displayedColumns: string[] = [
    'submittedBy',
    'name',
    'status',
    'startDate',
    'endDate',
    'type',
    'impressions',
    'clicks',
    'ctr',
    'vtc',
  ];
  dataSource = new MatTableDataSource<Campaign>(); // CAMPAIGNS);
  nextPage?: string;

  sortBy: string | null = null;
  sortOrder = SortOrder.Desc;
  defaultFrom = new Date('2025-01-01');
  defaultTo = new Date();
  from = this.defaultFrom;
  to = this.defaultTo;
  // filterForm = new FormGroup({
  //   range: new FormGroup({
  //     start: new FormControl<Date | null>(this.from),
  //     end: new FormControl<Date | null>(this.to),
  //   }),
  //   status: new FormControl<string[]| null>([]),
  //   type: new FormControl<string[]| null>([]),
  // });

  filters: any = {};

  campaigns: Campaign[] = [];
  campaignData: CampaignDailyMetric[] = [];
  totalData: CampaignMetric = {
    impressions: 0,
    clicks: 0,
    ctr: 0,
    vtc: 0,
  };
  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Total Campaigns',
        backgroundColor: '#002480',// 'rgba(148,159,177,0.2)',
        borderColor: '#002480', // 'rgba(148,159,177,1)',
        pointBackgroundColor: '#002480',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#002480',
        pointHoverBorderColor: '#fff',
        yAxisID: 'y',
        // pointHoverRadius: 5,
        // fill: 'origin',
      },
      {
        data: [],
        label: 'Display Campaigns',
        backgroundColor: '#3c66d3',// 'rgba(77,83,96,0.2)',
        borderColor: '#3c66d3',
        pointBackgroundColor: '#3c66d3',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#3c66d3',
        pointHoverBorderColor: '#fff',
        yAxisID: 'y',
        // pointHoverRadius: 5,
        // fill: 'origin',
      },
      {
        data: [],
        label: 'Video Campaigns',
        backgroundColor: 'rgb(244, 67, 54)',
        borderColor: 'rgb(244, 67, 54)',
        pointBackgroundColor: 'rgb(244, 67, 54)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: 'rgb(244, 67, 54)',
        pointHoverBorderColor: '#fff',
        yAxisID: 'y1',
        // pointHoverRadius: 5,
        // fill: 'origin',
      },
      {
        data: [],
        label: 'Search Campaigns',
        backgroundColor: 'rgb(131, 184, 26)',
        borderColor: 'rgb(131, 184, 26)',
        pointBackgroundColor: 'rgb(131, 184, 26)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: 'rgb(131, 184, 26)',
        pointHoverBorderColor: '#fff',
        yAxisID: 'y2',
        // pointHoverRadius: 5,
        // fill: 'origin',
      },
    ],
    labels: [],
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    resizeDelay: 500,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.1, // 0.5,
      },
      point:{
        radius: 0,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 8,
          color: 'rgba(0, 0, 0, 0.5)',
          font: {
            size: 11,
            family: 'Roboto'
          },
        },
        border: {
          display: false,
        }
      },
      y: {
        position: 'left',
        grid: {
          display: false,
        },
        ticks: {
          color: 'rgba(0, 0, 0, 0.5)',
          font: {
            size: 11,
            family: 'Roboto'
          },
        },
        border: {
          display: false,
        }
      },
      y1: {
        // type: 'linear',
        // display: true,
        position: 'right',

        // grid line settings
        grid: {
          display: false,
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        ticks: {
          color: 'rgba(0, 0, 0, 0.5)',
          font: {
            size: 11,
            family: 'Roboto'
          },
        },
        border: {
          display: false,
        }
      },
      y2: {
        // type: 'linear',
        display: false,
        position: 'right',

        // grid line settings
        grid: {
          display: false,
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        ticks: {
          color: 'rgba(0, 0, 0, 0.5)',
          font: {
            size: 11,
            family: 'Roboto'
          },
        },
        border: {
          display: false,
        }
      },
    },
    // datasets: {
    //   line: {
    //     showLine: false,
    //   },
    // },
    plugins: {
      legend: {
        display: false,
        // labels: {
        //   color: 'rgba(255, 0, 0, 0.01)',
        //   // font: {
        //   //   size: 9,
        //   //   family: 'Roboto'
        //   // },
        // },
      },
      tooltip: {
        backgroundColor: 'white',
        bodyColor: 'rgba(0, 0, 0, 0.6)',
        titleColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.2)',
        bodySpacing: 3,
        cornerRadius: 5,
        titleMarginBottom: 10,
        borderWidth: 1,
        padding: 10,
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = (label === 'CTR' || label === 'VTC') && context.parsed.y
              ? `${this.decimalPipe.transform(context.parsed.y, '1.2-2')}%`
              : this.decimalPipe.transform(context.parsed.y, '1.0-0');

            return `${label}: ${value}`;
          }
        },
        // intersect: false,
      }
      // tooltip: {
      //   type: 'line',
      //   scaleID: 'x',
      //   value: 'March',
      //   borderColor: 'orange',
      //   borderWidth: 2,
      //   label: {
      //     display: true,
      //     position: 'center',
      //     color: 'orange',
      //     content: 'LineAnno',
      //     font: {
      //       weight: 'bold',
      //     },
      //   },
      // },
    },
  };

  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  desktopSubscription!: Subscription;
  presets: SidenavMenuLink[] = [
    { name: 'All campaigns', path: './all' },
    { name: 'Unassigned', path: './unassigned' },
  ];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    public screen: ScreenService,
    private menu: MenuService,
    private service: CampaignsService,
  ) {}

  setVisibility = [true, true, true, true];
  testHide(index: number) {
    console.log('TEST', this.lineChartData.datasets[index]);
    // this.lineChartData.datasets[index].hide();
    if (this.setVisibility[index]) {
      this.chart?.chart?.hide(index);
      this.setVisibility[index] = !this.setVisibility[index];
    } else {
      this.chart?.chart?.show(index);
      this.setVisibility[index] = !this.setVisibility[index];
    }
  }

  ngOnInit(): void {
    this.menu.hasPageMenu.next(true);
    this.desktopSubscription = this.screen.isDesktop.subscribe((isDesktop: boolean) => {
      this.opened = isDesktop;
      // this.screen.isMenuOpened.next(this.menuOpened);
      this.menu.isPageMenuOpened.next(this.opened);
    });

    this.menu.pageMenuToggled.subscribe(() => this.toggleMenu());
    // this.filterForm.valueChanges.pipe(debounceTime(500)).subscribe(async (value) => {
    //   console.log('Filters values', value);

    //   if (value.range?.start && value.range?.end) {
    //     this.from = value.range.start;
    //     this.to = value.range.end;
    //   }

    //   if (value.status) {
    //     this.filters.status = value.status;
    //   } else {
    //     delete this.filters.status;
    //   }

    //   if (value.type) {
    //     this.filters.type = value.type;
    //   } else {
    //     delete this.filters.type;
    //   }

    //   delete this.nextPage;
    //   if (this.sortBy) {
    //     this.dataSource.data = await this.getCampaigns(this.from, this.to, this.filters, this.sortBy, this.sortOrder);
    //   } else {
    //     this.dataSource.data = await this.getCampaigns(this.from, this.to, this.filters);
    //   }
    // });
  }

  async ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
    this.isLoadingProgress = true;
    this.dataSource.data = await this.getCampaigns(this.from, this.to);
    this.isLoadingProgress = false;
  }

  ngOnDestroy(): void {
    this.menu.hasPageMenu.next(false);
    this.desktopSubscription.unsubscribe();
  }

  async reloadData(data: CampaignFilters) {
    delete this.nextPage;

    this.from = data.from;
    this.to = data.to;
    this.filters = data.filters;

    if (this.sortBy) {
      this.dataSource.data = await this.getCampaigns(this.from, this.to, this.filters, this.sortBy, this.sortOrder);
    } else {
      this.dataSource.data = await this.getCampaigns(this.from, this.to, this.filters);
    }
  }

  // resetFilters() {
  //   this.filterForm.setValue({
  //     range: {
  //       start: new Date('2023-01-01'),
  //       end: new Date(),
  //     },
  //     status: [],
  //     type: [],
  //   });
  // }

  async getCampaigns(
    from: Date,
    to: Date,
    filters: any = null,
    sortBy: string | null = null,
    sortOrder = SortOrder.Desc,
  ) {
    this.inProgress = true;
    const { campaigns, data, total, next } = await this.service.list(from, to, sortBy, sortOrder, filters, this.nextPage);
    this.campaignData = data;
    this.totalData = total;
    this.composeChartData(data);
    this.inProgress = false;
    this.nextPage = next;

    return campaigns.map(c => {
      let company = null;

      c.name.replace(/(^|\s)(ANN|KDRV|KEZI|KIMT|WAAY|WFFT|WLFI|WTHI|WTVA|KIAH|KDAF|WLOV|KADN|WEVV|KITV|WSIL|WXOW|WQOW|WKOW|KWWL|WREX|WAOW|KVOA|WJRT|WCOV)(\s|$)/gi, function (match) {
        company = match.toUpperCase();

        return match;
      });

      if (company) {
        c.submittedBy = { company } as any;
      }

      return c;
    });
  }

  composeChartData(data: CampaignDailyMetric[]) {
    const [
      impressionsDataset,
      clicksDataset,
      ctrDataset,
      vtcDataset
    ] = this.lineChartData.datasets;
    const labels: string[] = new Array(data.length);

    impressionsDataset.data = new Array(data.length);
    clicksDataset.data = new Array(data.length);
    ctrDataset.data = new Array(data.length);
    vtcDataset.data = new Array(data.length);

    this.totalData.clicks = 0;
    this.totalData.ctr = 0;
    this.totalData.vtc = 0;
    for (let i = 0; i < data.length; i++) {
      impressionsDataset.data[i] = (data[i] as any).impressions.display + (data[i] as any).impressions.video + (data[i] as any).impressions.search;
      clicksDataset.data[i] = (data[i] as any).impressions.display;
      ctrDataset.data[i] = (data[i] as any).impressions.video;
      vtcDataset.data[i] = (data[i] as any).impressions.search;
      labels[i] = this.datePipe.transform(data[i].date, 'MMM d, y') ?? '-';

      this.totalData.clicks += (data[i] as any).impressions.display;
      this.totalData.ctr += (data[i] as any).impressions.video;
      this.totalData.vtc += (data[i] as any).impressions.search;
    }
    // for (let i = 0; i < data.length; i++) {
    //   impressionsDataset.data[i] = data[i].impressions;
    //   clicksDataset.data[i] = data[i].clicks;
    //   ctrDataset.data[i] = data[i].ctr;
    //   vtcDataset.data[i] = data[i].vtc;
    //   labels[i] = this.datePipe.transform(data[i].date, 'MMM d, y') ?? '-';
    // }

    this.lineChartData = {
      datasets: [
        impressionsDataset,
        clicksDataset,
        ctrDataset,
        vtcDataset,
      ],
      labels,
    };
  }

  async checkScroll(event: any) {
    const { scrollTop, scrollHeight, offsetHeight } = event.srcElement;
    this.scrollPosition = scrollTop;
    if (!this.inProgress) {
      const scrollValue = (scrollHeight - scrollTop) / offsetHeight;
      if (scrollValue < 1.2) {
        const assets = await this.getCampaigns(this.from, this.to, this.filters, this.sortBy, this.sortOrder);
        this.dataSource.data = this.dataSource.data.concat(assets);
      } else if (scrollValue < 2) {
        this.isLoadingProgress = true;
      } else {
        this.isLoadingProgress = false;
      }
    }
  }

  getRouteAnimationState() {
    // console.log('WOW', this.route.snapshot);
    return this.router.url; // getRouteAnimationState(this.route);
  }

  async sortData(sortState: Sort) {
    const sortMap: { [key: string]: string } = {
      submittedBy: 'submitted_by',
      startDate: 'start_date',
      endDate: 'end_date',
    };
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    console.log('Sorting is happening...', sortState);
    let campaigns: Campaign[];

    delete this.nextPage;
    if (sortState.direction === SortOrder.Asc) {
      this.sortBy = sortMap[sortState.active] ?? sortState.active;
      this.sortOrder = SortOrder.Asc;
      campaigns = await this.getCampaigns(this.from, this.to, this.filters, this.sortBy, this.sortOrder);
    } else if (sortState.direction === SortOrder.Desc) {
      this.sortBy = sortMap[sortState.active] ?? sortState.active;
      this.sortOrder = SortOrder.Desc;
      campaigns = await this.getCampaigns(this.from, this.to, this.filters, this.sortBy, this.sortOrder);
    } else {
      campaigns = await this.getCampaigns(this.from, this.to);
    }

    console.log('CAMPAIGNS', campaigns);
    if (campaigns) {
      this.dataSource.data = campaigns;
    }
  }

  onMenuMouseEnter() {
    console.log('MOUSE ENTER');
    // this.isFocusedOnMenu = true;
    // this.isFocusedOnRail = false;
  }

  onMenuMouseLeave() {
    console.log('MOUSE LEAVE');
    // setTimeout(() => {
    //   if (this.opened && !this.isFocusedOnMenu && !this.isFocusedOnRail) {
    //     this.opened = false;
    //   }

    //   this.isFocusedOnMenu = false;
    // }, 100);
  }

  async toggleMenu() {
    // this.openInProgress = true;
    // await this.sidenav.toggle();
    this.opened = !this.opened;
    this.menu.isPageMenuOpened.next(this.opened);

    // this.openInProgress = false;
  }
}
