import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  private mobileMediaQuery = window.matchMedia("screen and (max-width: 635px)");
  private tabletMediaQuery = window.matchMedia("screen and (min-width: 636px) and (max-width: 1024px)");
  private desktopMediaQuery = window.matchMedia("screen and (min-width: 1025px)");

  private _headerHeight = 64;
  private _viewportHeight = 1200;

  private isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.mobileMediaQuery.matches);
  private isTablet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.tabletMediaQuery.matches);
  private isDesktop$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.desktopMediaQuery.matches);

  private isMenuOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private headerHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(this._headerHeight);
  private viewportHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(this._viewportHeight);

  public get isMobile() { return this.isMobile$; }
  public get isTablet() { return this.isTablet$; }
  public get isDesktop() { return this.isDesktop$; }

  public get isMenuOpened() { return this.isMenuOpened$; }
  public get headerHeight() { return this.headerHeight$; }
  public get viewportHeight() { return this.viewportHeight$; }
  public get minHeight() {
    return combineLatest([this.viewportHeight$, this.headerHeight$])
      .pipe(
        map(([vHeight, hHeight]) => vHeight - hHeight)
      );
  }

  constructor() { }

  init() {
    const that = this;

    this.mobileMediaQuery.addEventListener('change', function (this: MediaQueryList, ev: MediaQueryListEvent) {
      that.isMobile$.next(this.matches);
    });

    this.tabletMediaQuery.addEventListener('change', function (this: MediaQueryList, ev: MediaQueryListEvent) {
      that.isTablet$.next(this.matches);
    });

    this.desktopMediaQuery.addEventListener('change', function (this: MediaQueryList, ev: MediaQueryListEvent) {
      that.isDesktop$.next(this.matches);
    });
  }
}
