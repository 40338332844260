import { Component } from '@angular/core';
import { ChildrenOutletContexts, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRippleModule } from '@angular/material/core';

import { SidenavMenuComponent, SidenavMenuLink } from '../core/components/sidenav/sidenav-menu/sidenav-menu.component';
import { SidenavContainerComponent } from '../core/components/sidenav/sidenav-container/sidenav-container.component';
import { HeaderComponent } from '../core/components/header/header.component';
import { FooterComponent } from '../core/components/footer/footer.component';
import { UserService } from '../core/services/user.service';
import { MenuService } from '../core/services/menu.service';
import { ScreenService } from '../core/services/screen.service';

export interface NavigationRailLink {
  name: string;
  icon: string;
  path: string;
  links?: SidenavMenuLink[];
};

@Component({
  selector: 'iml-pages',
  standalone: true,
  imports: [
    SidenavMenuComponent,
    SidenavContainerComponent,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatRippleModule,
    HeaderComponent,
    FooterComponent,
  ],
  templateUrl: './pages.component.html',
  styleUrl: './pages.component.scss',
})
export class PagesComponent {
  currentYear = new Date().getFullYear();
  opened = false;
  pageMenuOpened = false;
  isFocusedOnRail = false;
  isFocusedOnMenu = false;
  isLinkExact = false;

  links: SidenavMenuLink[] = [
    { name: 'Campaigns', path: '/campaigns', icon: 'monitoring' }, // campaign // analytics
    { name: 'Assets', path: '/creatives', icon: 'newspaper' }, // dashboard // RFPs and Creatives // Creatives
    // gallery_thumbnail // newspaper // newsmode // art_track // planner_banner_ad_pt // ad_units // dashboard
    {
      name: 'New Request',
      icon: 'order_approve',
      path: '/submissions',
      links: [
        {
          name: 'Requests',
          isExpanded: true,
          links: [
            { name: 'Submit RFP', path: '/submissions/requests/rfp' },
            { name: 'Submit Creative', path: '/submissions/requests/creative' },
            // { name: 'Request Dashboard', path: '/submissions/requests/dashboard' },
          ],
        },
        {
          name: 'Orders',
          isExpanded: true,
          links: [
            { name: 'Display Campaign', path: '/submissions/orders/display-campaign' },
            { name: 'Video Campaign', path: '/submissions/orders/video-campaign' },
            { name: 'Audio Campaign', path: '/submissions/orders/audio-campaign' },
            { name: 'NewsDriver Campaign', path: '/submissions/orders/newsdriver-campaign' },
            { name: 'Search Campaign', path: '/submissions/orders/search-campaign' },
            { name: 'DOOH Campaign', path: '/submissions/orders/dooh-campaign' },
          ],
        },
      ],
    },
    {
      name: 'Resources',
      icon: 'school',
      path: '/resources',
      links: [
        // {
        //   name: 'Resources',
        //   isExpanded: true,
        //   links: [
        //     { name: 'CPM Calculator', path: '/resources/cpm-calculator' },
        //     { name: 'SODA Builder', isExternal: true, path: 'https://soda.secureiml.com/' },
        //   ],
        // },
        {
          name: 'Case Studies',
          path: '/resources/case-studies',
          isExpanded: true,
          links: [
            { name: 'How effective are Drivers?', path: '/resources/case-studies/KyNhRPcXM6lNZF' },
            { name: 'So You Want Conversions', path: '/resources/case-studies/KzxHhSAGuc1CSy' },
            { name: 'Lawyers, Law Firms, Legal Advocacy', path: '/resources/case-studies/E0t5tr5dBcuNPg' },
            { name: 'To Gate or Not to Gate Your Content?', path: '/resources/case-studies/0fRBgqsdTmb1T5' },
            // { name: 'How effective are Drivers?', path: '/resources/case-studies/case-study-1' },
            // { name: 'Woman\'s Sportswear', path: '/resources/case-studies/case-study-2' },
            // { name: 'Consumer Technology Store', path: '/resources/case-studies/case-study-3' },
            // { name: 'Quick Service Restaraunt', path: '/resources/case-studies/case-study-4' },
          ],
        },
        {
          name: 'Product One Sheeter',
          path: '/resources/one-sheeters',
          links: [
            { name: 'Geo-fencing', path: '/resources/one-sheeters/kEdlOJMMY1JyE5' },
            { name: 'Digital Campaigns | Audience Development', path: '/resources/one-sheeters/ZGS3K4BTuOsxCX' },
            { name: 'Digital Out of Home | DOOH', path: '/resources/one-sheeters/iIWbAI0zjZ5Jq8' },
            { name: 'NewsDriver | Press Release & Pitch Insurance', path: '/resources/one-sheeters/CBtb1nv4VPcMZu' },
            { name: 'Voter Targeted Campaigns', path: '/resources/one-sheeters/1CB8Kbe4pL7Jf7' },
            { name: 'Ad Spec Sheet', path: '/resources/one-sheeters/lIeB9bEEdlm90D' },
            { name: 'Programmatic Audio', path: '/resources/one-sheeters/yYt83h9dUGEER9' },
            { name: 'CRM Targeting | Digital Best Practices', path: '/resources/one-sheeters/baIzvUiLIC9Ppd' },
            { name: 'SODA | Social Open Display Ads', path: '/resources/one-sheeters/SNfO6R7CQRKkGq' },
            { name: 'Understanding TikTok', path: '/resources/one-sheeters/DFd1mPS7jRdDQB' },
            { name: 'Budget vs Impression Media Buying', path: '/resources/one-sheeters/3Zmjyb5V35n11B' },
            { name: 'Keyword Search Advertising', path: '/resources/one-sheeters/3SDBYpyyvzk43m' },
            { name: 'YouTube Advertising', path: '/resources/one-sheeters/V9lRikmubWoP1M' },
            // { name: 'CRM Targeting', path: '/resources/one-sheeters/os-1' },
            // { name: 'Collateral Geofencing', path: '/resources/one-sheeters/os-2' },
          ],
        },
        {
          name: 'Vertical One Sheeter',
          path: '/resources/one-sheeters',
          links: [
            { name: 'Restaurant Customer Targeting ', path: '/resources/one-sheeters/QtuclYKmH7udbO' },
            { name: 'Fashion Retail Sales Package', path: '/resources/one-sheeters/qdDUbanNgLjs59' },
            { name: 'Jewelry Package', path: '/resources/one-sheeters/CkeFYxwWfD8cbl' },
            { name: 'Tax Services', path: '/resources/one-sheeters/2ZVOS2x4hxNImM' },
            { name: 'Auto Service & Repair', path: '/resources/one-sheeters/z6BEHwA8wBXum8' },
          ],
        },
      ],
    },
    // { name: 'Campaigns', path: '/campaigns', icon: 'monitoring' },
    { name: 'CPM Calc', path: '/resources/cpm-calculator', icon: 'calculate' },
    { name: 'SODA Builder', isExternal: true, path: 'https://soda.secureiml.com/', icon: 'dashboard' },
  ];

  // sublinks: { [key: string]: SidenavMenuLink[] } = {};
  sublinks: SidenavMenuLink[] = [];
  // links = [
  //   { name: 'Campaigns', path: '/campaigns', icon: 'monitoring' }, // campaign // analytics
  //   { name: 'Assets', path: '/creatives', icon: 'planner_banner_ad_pt' }, // dashboard // RFPs and Creatives // Creatives
  //   // gallery_thumbnail // newspaper // newsmode // art_track // planner_banner_ad_pt // ad_units // dashboard
  //   { name: 'New Request', path: '/submissions', icon: 'order_approve' }, // construction // list // Request & Order // Request/Order
  //   { name: 'Resources', path: '/resources', icon: 'school' }, // construction // Resources & Help // Help

  //   // { name: 'Get Started', path: '/campaigns', icon: 'apps' }, // campaign // analytics
  //   // { name: 'Develop', path: '/creatives', icon: 'code' }, // RFPs and Creatives // Creatives
  //   // // gallery_thumbnail // newspaper // newsmode // art_track // planner_banner_ad_pt // ad_units // dashboard
  //   // { name: 'Foundations', path: '/submissions', icon: 'book' }, // list // Request & Order // Request/Order
  //   // { name: 'Styles', path: '/resources', icon: 'palette' }, // Resources & Help // Help

  //   // { name: 'Campaigns', path: '/campaigns', icon: 'monitoring' }, // campaign
  //   // { name: 'RFPs and Creatives', path: '/creatives', icon: 'dashboard' }, // RFPs and Creatives // Creatives
  //   // // gallery_thumbnail // newspaper // newsmode // art_track // planner_banner_ad_pt // ad_units // dashboard
  //   // { name: 'Request & Order', path: '/submissions', icon: 'construction' }, // list // Request & Order // Request/Order
  //   // { name: 'Resources & Help', path: '/resources', icon: 'help_center' }, // Resources & Help // Help
  // ];
  constructor(
    private contexts: ChildrenOutletContexts,
    private user: UserService,
    public screen: ScreenService,
    public menu: MenuService
  ) {
    // for (const link of this.links) {
    //   if (Array.isArray(link.links)) {
    //     this.sublinks[link.path] = link.links;
    //   }
    // }
    // this.user.requestUserInfo().then((r) => console.log('USER', ));
  }

  closeMenu() {
    this.toggleMenu();
  }

  openSubMenu() {
    this.opened = true;
    this.menu.isMainMenuOpened.next(this.opened);
  }

  closeSubMenu() {
    this.opened = false;
    this.menu.isMainMenuOpened.next(this.opened);
  }

  toggleMenu() {
    if (this.menu.hasPageMenu.value) {
      this.menu.togglePageMenu();
    } else {
      this.opened = !this.opened;
      this.menu.isMainMenuOpened.next(this.opened);
    }
  }

  getRouteAnimationData() {
    console.log('WOW', this.contexts.getContext('primary')?.route?.snapshot?.title);
    return this.contexts.getContext('primary')?.route?.snapshot?.title; // .data?.['animation'];
  }

  onRailMouseEnter(index: number) {
    this.isFocusedOnRail = true;
    this.isFocusedOnMenu = false;

    console.log('WHAT', index);
    if (Array.isArray(this.links[index].links)) {
      this.sublinks = this.links[index].links as any; // TODO: fix
      this.opened = true;
    } else {
      this.opened = false;
    }
  }

  onMenuMouseEnter() {
    console.log('MOUSE ENTER');
    this.isFocusedOnMenu = true;
    this.isFocusedOnRail = false;
  }

  onRailMouseLeave() {
    this.isFocusedOnRail = false;
    console.log('MOUSE LEAVE');
    setTimeout(() => {
      if (this.opened && !this.isFocusedOnMenu && !this.isFocusedOnRail) {
        this.opened = false;
      }
      console.log('LEAVE 11111', this.opened, this.isFocusedOnMenu, this.isFocusedOnRail);
    }, 100);
  }

  onMenuMouseLeave() {
    console.log('MOUSE LEAVE');
    setTimeout(() => {
      if (this.opened && !this.isFocusedOnMenu && !this.isFocusedOnRail) {
        this.opened = false;
      }

      this.isFocusedOnMenu = false;
    }, 100);
  }

  onRouterLinkActive(isActive: boolean, shouldTrack = false) {
    if (shouldTrack) {
      this.isLinkExact = isActive;
    }
  }
}
