import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";
import { ActivatedRoute, ChildrenOutletContexts } from "@angular/router";

// ()=>A});
// const e={
//   red:{id:"red",thumbnailHex:"#FFDAD9",thumbnailHexDarkMode:"#5c3f3f",thumbnailHoverImage:"static/assets/m3-red-theming-thumbnail.jpg",thumbnailHoverImageDarkMode:"static/assets/m3-red-theming-thumbnail-dark.jpg"},
//   green:{id:"green",thumbnailHex:"#D9E7CB",thumbnailHexDarkMode:"#3e4a36",thumbnailHoverImage:"static/assets/m3-green-theming-thumbnail.jpg",thumbnailHoverImageDarkMode:"static/assets/m3-green-theming-thumbnail-dark.jpg"},
//   yellow:{id:"yellow",thumbnailHex:"#E6E4BF",thumbnailHexDarkMode:"#48482D",thumbnailHoverImage:"static/assets/m3-yellow-theming-thumbnail.jpg",thumbnailHoverImageDarkMode:"static/assets/m3-yellow-theming-thumbnail-dark.jpg"}
// },
// a={STANDARD_EASE:"cubic-bezier(.2,0,0,1)",LINEAR:"linear"},
// v={_235ms:"235ms",xshort:"100ms",short:"200ms",medium:"300ms",long:"500ms"},
// p={BREAKPOINTS:{MOBILE_BREAKPOINT_WIDTH:600,TABLET_BREAKPOINT_WIDTH:960,LAPTOP_BREAKPOINT_WIDTH:1294,DESKTOP_BREAKPOINT_WIDTH:1800},
// MIO_ANIMATIONS:{SLIDE_UP_DOWN:`${v._235ms} ${a.STANDARD_EASE}`,LEFT_NAV_SLIDE_ANIMATION:`${v.medium} ${a.STANDARD_EASE}`,
// LEFT_NAV_CONTENT_CHANGE_FADE_IN:`${v.short} ${v.short} ${a.LINEAR}`,LEFT_NAV_CONTENT_CHANGE_FADE_OUT:`${v.xshort} ${a.STANDARD_EASE}`},MIO_COOKIE_NOTICE_CONFIRM:"cookie_notice_confirmed",MIO_CURVES:a,MIO_DURATIONS:v,MIO_CURRENT_MODE_KEY:"current_mode",MIO_CURRENT_MODE_LIGHT:"light",MIO_CURRENT_MODE_DARK:"dark"},b={title:null,slug:null,children:[]},A=

// `200ms 200ms linear`
// `100ms cubic-bezier(.2,0,0,1)`

// (0,z.X$)("fadeInOutSectionModal", [
//   (0,z.eR)(":enter", [
//     (0,z.oB)({ opacity:0,transform:"translateX(-10px)" }),
//     (0,z.jt)(m.tl.MIO_ANIMATIONS.LEFT_NAV_CONTENT_CHANGE_FADE_IN,
//       (0,z.oB)({opacity:1,transform:"translateX(0)"})
//     )
//   ]),
//   (0,z.eR)(":leave",[
//     (0,z.jt)(m.tl.MIO_ANIMATIONS.LEFT_NAV_CONTENT_CHANGE_FADE_OUT,
//       (0,z.oB)({ opacity:0,transform:"translateX(-10px)" })
//     )
//   ])
// ]),
// (0,z.X$)("fadeInOutSubsectionModal",[(0,z.eR)(":enter",[(0,z.oB)({opacity:0,transform:"translateX(10px)"}),(0,z.jt)(m.tl.MIO_ANIMATIONS.LEFT_NAV_CONTENT_CHANGE_FADE_IN,(0,z.oB)({opacity:1,transform:"translateX(0)"}))]),(0,z.eR)(":leave",[(0,z.jt)(m.tl.MIO_ANIMATIONS.LEFT_NAV_CONTENT_CHANGE_FADE_OUT,(0,z.oB)({opacity:0,transform:"translateX(10px)"}))])])]}}),o})();const gn=["mwcButton"];let _n=(()=>{class o{constructor(t){this.localStorageService=t,this.AnalyticsKeys=Y.sU.AnalyticsKeys}ngAfterViewInit(){setTimeout(()=>{this.mwcButtonsRef.forEach(t=>{this.mwcButtonShadowRoot=t.nativeElement.shadowRoot,this.customizeMWCButtonStyle(".mdc-button","styl

export const getRouteAnimationState = (route: ActivatedRoute) => {
  console.log('WOW', route?.snapshot?.url?.join('')); // contexts.getContext('primary')?.
  return route?.snapshot?.url?.join(''); // .data?.['animation'];
}

// (0,z.X$)("fadeInOut", [
//   (0,z.eR)(":enter", [
//     (0,z.oB)({ opacity:0 }),
//     (0,z.jt)(m.tl.MIO_ANIMATIONS.LEFT_NAV_CONTENT_CHANGE_FADE_IN, (0,z.oB)({ opacity:1 }))
//   ]),
//   (0,z.eR)(":leave",[
//     (0,z.jt)(m.tl.MIO_ANIMATIONS.LEFT_NAV_CONTENT_CHANGE_FADE_OUT, (0,z.oB)({ opacity:0 }))
//   ])
// ]),
// (0,z.X$)("fadeInOutSectionModal",[
//   (0,z.eR)(":enter",[
//     (0,z.oB)({ opacity:0, transform:"translateX(-10px)" }),
//     (0,z.jt)(m.tl.MIO_ANIMATIONS.LEFT_NAV_CONTENT_CHANGE_FADE_IN, (0,z.oB)({ opacity:1, transform:"translateX(0)" }))
//   ]),
//   (0,z.eR)(":leave",[
//     (0,z.jt)(m.tl.MIO_ANIMATIONS.LEFT_NAV_CONTENT_CHANGE_FADE_OUT, (0,z.oB)({ opacity:0, transform:"translateX(-10px)"}))
//   ])
// ]),
// (0,z.X$)("fadeInOutSubsectionModal", [
//   (0,z.eR)(":enter",[
//     (0,z.oB)({ opacity:0, transform:"translateX(10px)" }),
//     (0,z.jt)(m.tl.MIO_ANIMATIONS.LEFT_NAV_CONTENT_CHANGE_FADE_IN, (0,z.oB)({ opacity:1, transform:"translateX(0)" }))
//   ]),
//   (0,z.eR)(":leave",[
//     (0,z.jt)(m.tl.MIO_ANIMATIONS.LEFT_NAV_CONTENT_CHANGE_FADE_OUT,(0,z.oB)({ opacity:0, transform:"translateX(10px)" }))
//   ])
// ])

export const fadeInOutPage = trigger('fadeInOutPage', [
  transition('* <=> *', [
    // query(':enter', [
      style({ opacity: 0, transform: 'translateY(10px)', overflow: 'hidden !important' }),
      // , backgroundColor: 'red', border: '2px solid green'
      animate('200ms 200ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
        opacity: 1,
        transform: 'translateY(0)', overflow: 'hidden !important',
      })),
    // ], { optional: true }), //
    // query(':leave', [
    //   style({ opacity: 1,
    //     //  transform: 'translateY(0)',
    //   }),
    //   animate('100ms cubic-bezier(.2,0,0,1)', style({
    //     opacity: 0,
    //     // transform: 'translateY(-10px)',
    //   })),
    // ], { optional: true }),
  ]),

  // transition('HomePage <=> AboutPage', [
  //   style({ position: 'relative' }),
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       top: 0,
  //       left: 0,
  //       width: '100%'
  //     })
  //   ]),
  //   query(':enter', [
  //     style({ left: '-100%' })
  //   ], { optional: true }),
  //   query(':leave', animateChild(), { optional: true }),
  //   group([
  //     query(':leave', [
  //       animate('300ms ease-out', style({ left: '100%' }))
  //     ], { optional: true }),
  //     query(':enter', [
  //       animate('300ms ease-out', style({ left: '0%' }))
  //     ], { optional: true }),
  //   ]),
  // ]),
  // transition('* <=> *', [
  //   style({ position: 'relative' }),
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       top: 0,
  //       left: 0,
  //       width: '100%'
  //     })
  //   ], { optional: true }),
  //   query(':enter', [
  //     style({ left: '-100%' })
  //   ], { optional: true }),
  //   query(':leave', animateChild(), { optional: true }),
  //   group([
  //     query(':leave', [
  //       animate('200ms ease-out', style({ left: '100%', opacity: 0 }))
  //     ], { optional: true }),
  //     query(':enter', [
  //       animate('300ms ease-out', style({ left: '0%' }))
  //     ], { optional: true }),
  //     query('@*', animateChild(), { optional: true })
  //   ]),
  // ])
]);

export const fadeInOutSectionModal = trigger('fadeInOutSectionModal', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(10px)', }),
    animate('200ms 200ms linear', style({
      opacity:1,
      transform: 'translateX(0)'
    })),
  ]),
  transition(':leave', [
    animate('100ms cubic-bezier(.2,0,0,1)', style({
      opacity:0,
      transform: 'translateX(-10px)',
    })),
  ]),
]);

export const fadeInOutSubsectionModal = trigger('fadeInOutSubsectionModal', [
  transition(':enter', [
    style({ opacity:0, transform: 'translateX(10px)', }),
    animate('200ms 200ms linear', style({
      opacity:1,
      transform: 'translateX(0)',
    }))
  ]),
  transition(':leave', [
    animate('100ms cubic-bezier(.2,0,0,1)', style({
      opacity:0,
      transform: 'translateX(10px)',
    }))
  ])
]);

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('HomePage <=> AboutPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%' })
      ], { optional: true }),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%' }))
        ], { optional: true }),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%' }))
        ], { optional: true }),
      ]),
    ]),
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ], { optional: true }),
      query(':enter', [
        style({ left: '-100%' })
      ], { optional: true }),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ left: '100%', opacity: 0 }))
        ], { optional: true }),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%' }))
        ], { optional: true }),
        query('@*', animateChild(), { optional: true })
      ]),
    ])
  ]);
