import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { SidenavMenuComponent, SidenavMenuLink } from '../../core/components/sidenav/sidenav-menu/sidenav-menu.component';
import { SidenavContainerComponent } from '../../core/components/sidenav/sidenav-container/sidenav-container.component';
import { fadeInOutPage, getRouteAnimationState } from '../../animations';
import { ScreenService } from '../../core/services/screen.service';
import { MenuService } from '../../core/services/menu.service';
import { Subscription } from 'rxjs';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';

@Component({
  selector: 'iml-submissions',
  standalone: true,
  imports: [
    SidenavMenuComponent,
    SidenavContainerComponent,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
  ],
  templateUrl: './submissions.component.html',
  styleUrl: './submissions.component.scss',
  animations: [fadeInOutPage],
})
export class SubmissionsComponent implements OnInit, OnDestroy {
  opened = true;

  links: SidenavMenuLink[] = [
    {
      name: 'Requests',
      isExpanded: true,
      links: [
        { name: 'Submit RFP', path: '/submissions/requests/rfp' },
        { name: 'Submit Creative', path: '/submissions/requests/creative' },
        { name: 'Request Dashboard', path: '/submissions/requests/dashboard' },
      ],
    },
    {
      name: 'Orders',
      isExpanded: true,
      links: [
        { name: 'Display Campaign', path: '/submissions/orders/display-campaign' },
        { name: 'Video Campaign', path: '/submissions/orders/video-campaign' },
        { name: 'Audio Campaign', path: '/submissions/orders/audio-campaign' },
        { name: 'NewsDriver Campaign', path: '/submissions/orders/newsdriver-campaign' },
        { name: 'Search Campaign', path: '/submissions/orders/search-campaign' },
        { name: 'DOOH Campaign', path: '/submissions/orders/dooh-campaign' },
      ],
    },
  ];

  desktopSubscription!: Subscription;
  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public screen: ScreenService,
    private menu: MenuService,
  ) {}

  ngOnInit(): void {
    this.menu.hasPageMenu.next(true);
    this.desktopSubscription = this.screen.isDesktop.subscribe((isDesktop: boolean) => {
      this.opened = isDesktop;
      // this.screen.isMenuOpened.next(this.menuOpened);
      this.menu.isPageMenuOpened.next(this.opened);
    });

    this.menu.pageMenuToggled.subscribe(() => this.toggleMenu());
  }

  ngOnDestroy(): void {
    this.menu.hasPageMenu.next(false);
    this.desktopSubscription.unsubscribe();
  }

  getRouteAnimationState() {
    // console.log('WOW', this.route.snapshot);
    return this.router.url; // getRouteAnimationState(this.route);
  }

  async toggleMenu() {
    if (!this.screen.isDesktop.value) {
      return;
    }

    // this.openInProgress = true;
    // await this.sidenav.toggle();
    this.opened = !this.opened;
    this.menu.isPageMenuOpened.next(this.opened);

    // this.openInProgress = false;
  }
}
