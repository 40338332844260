import { Routes } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { CampaignsComponent } from './pages/campaigns/campaigns.component';
import { CreativesComponent } from './pages/creatives/creatives.component';

import { SubmissionsComponent } from './pages/submissions/submissions.component';
import { RfpComponent } from './pages/submissions/requests/rfp/rfp.component';
import { CreativeComponent } from './pages/submissions/requests/creative/creative.component';
import { DashboardComponent } from './pages/submissions/requests/dashboard/dashboard.component';
import { DisplayCampaignComponent } from './pages/submissions/orders/display-campaign/display-campaign.component';
import { VideoCampaignComponent } from './pages/submissions/orders/video-campaign/video-campaign.component';
import { AudioCampaignComponent } from './pages/submissions/orders/audio-campaign/audio-campaign.component';
import { NewsdriverCampaignComponent } from './pages/submissions/orders/newsdriver-campaign/newsdriver-campaign.component';
import { SearchCampaignComponent } from './pages/submissions/orders/search-campaign/search-campaign.component';
import { DoohCampaignComponent } from './pages/submissions/orders/dooh-campaign/dooh-campaign.component';

import { ResourcesComponent } from './pages/resources/resources.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { unsavedChangesGuard } from './core/guards/unsaved-changes.guard';
import { authGuard } from './core/guards/auth.guard';

import { CpmCalculatorComponent } from './pages/resources/cpm-calculator/cpm-calculator.component';
import { CaseStudiesComponent } from './pages/resources/case-studies/case-studies.component';
import { OneSheetersComponent } from './pages/resources/one-sheeters/one-sheeters.component';
import { SearchComponent } from './pages/search/search.component';
import { LoginComponent } from './login/login.component';

export const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    children: [
      {
        path: '',
        redirectTo: '/campaigns',
        pathMatch: 'full',
      },
      {
        path: 'campaigns',
        title: 'Campaign Manager',
        component: CampaignsComponent,
      },
      {
        path: 'creatives',
        title: 'RFPs & Creatives',
        component: CreativesComponent,
      },
      {
        path: 'submissions',
        title: 'Request & Order',
        component: SubmissionsComponent,
        children: [
          {
            path: '',
            redirectTo: '/submissions/requests/rfp',
            pathMatch: 'full',
          },
          {
            path: 'requests/rfp',
            title: 'Submit RFP Request',
            component: RfpComponent,
            canDeactivate: [unsavedChangesGuard]
          },
          {
            path: 'requests/creative',
            title: 'Submit Creative Request',
            component: CreativeComponent,
            canDeactivate: [unsavedChangesGuard]
          },
          {
            path: 'requests/dashboard',
            title: 'Request Dashboard',
            component: DashboardComponent,
            canDeactivate: [unsavedChangesGuard]
          },
          {
            path: 'orders/display-campaign',
            title: 'Order Display Campaign',
            component: DisplayCampaignComponent,
            canDeactivate: [unsavedChangesGuard]
          },
          {
            path: 'orders/video-campaign',
            title: 'Order Video Campaign',
            component: VideoCampaignComponent,
            canDeactivate: [unsavedChangesGuard]
          },
          {
            path: 'orders/audio-campaign',
            title: 'Order Audio Campaign',
            component: AudioCampaignComponent,
            canDeactivate: [unsavedChangesGuard]
          },
          {
            path: 'orders/newsdriver-campaign',
            title: 'Order NewsDriver Campaign',
            component: NewsdriverCampaignComponent,
            canDeactivate: [unsavedChangesGuard]
          },
          {
            path: 'orders/search-campaign',
            title: 'Order Search Campaign',
            component: SearchCampaignComponent,
            canDeactivate: [unsavedChangesGuard]
          },
          {
            path: 'orders/dooh-campaign',
            title: 'Order DOOH Campaign',
            component: DoohCampaignComponent,
            canDeactivate: [unsavedChangesGuard]
          },
        ],
      },
      {
        path: 'resources',
        title: 'Resources & Help',
        component: ResourcesComponent,
        children: [
          {
            path: '',
            redirectTo: '/resources/cpm-calculator',
            pathMatch: 'full',
          },
          {
            path: 'cpm-calculator',
            title: 'CPM Calculator',
            component: CpmCalculatorComponent,
          },
          {
            path: 'case-studies/:id',
            title: 'Case Studies',
            component: CaseStudiesComponent,
          },
          {
            path: 'one-sheeters/:id',
            title: 'Product One Sheeter',
            component: OneSheetersComponent,
          },
          {
            path: 'one-sheeters/:id',
            title: 'Vertical One Sheeter',
            component: OneSheetersComponent,
          },
        ],
      },
      {
        path: 'search',
        title: 'Search',
        component: SearchComponent,
      },
    ],
  },
  {
    path: 'login',
    title: 'Login',
    component: LoginComponent,
  },
  {
    path: '**',
    title: 'Page Not Found',
    component: PageNotFoundComponent,
  },
];
